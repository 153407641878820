@charset "UTF-8";
/* Convert px to em */
/* Return line-height by default or by px */
.k__alert {
  text-align: center;
  background: #e9e9e9;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  color: #636363;
  font-size: 0.9rem;
  animation: appear 0.2s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__alert.--info {
    background-color: #e7eeff;
    color: #4666ad; }
  .k__alert.--danger {
    background-color: #fde8e7;
    color: #9a1c1c; }
  .k__alert.--success {
    background-color: #e9f8ea;
    color: #04543f; }
  .k__alert.--banner {
    text-align: left;
    background: #fff;
    color: inherit; }

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.95); }
  to {
    opacity: 1;
    transform: scale(1); } }

.--appear {
  animation: appear 0.2s; }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes reveal {
  from {
    opacity: 0;
    transform: scale(2); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes slideFromBottom {
  from {
    transform: translateY(1rem); }
  to {
    transform: translateY(0); } }

.--slideFromBottomSoft {
  animation: slideFromBottomSoft 0.2s; }

@keyframes slideFromBottomSoft {
  from {
    opacity: 0;
    transform: translateY(0.5rem); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes refresh {
  from {
    transform: rotateZ(0); }
  to {
    transform: rotateZ(360deg); } }

.--fade {
  animation: fade 0.2s; }

.k__img__area {
  background: rgba(0, 0, 0, 0.01);
  color: inherit;
  width: 100%;
  border: dashed rgba(0, 0, 0, 0.1) 2px;
  margin-top: 2rem;
  border-radius: 0.5rem; }
  .k__img__area .--hidden {
    height: 4rem; }

.k__banner {
  background: #4e81ab;
  padding: 1.618rem 0;
  background: linear-gradient(106.92deg, #47cff4 0%, #0b8db0 100%); }
  .k__banner .k__calendar__header {
    color: #fff;
    padding-bottom: 0; }
  .k__banner .k__calendar__header__title {
    font-size: 2rem;
    margin: 0 1rem; }
    .k__banner .k__calendar__header__title::first-letter {
      text-transform: uppercase; }
  .k__banner .k__tel {
    position: absolute;
    top: 50%;
    right: 1.618rem;
    transform: translateY(-50%);
    color: #fff; }
    .k__banner .k__tel::before {
      border-left-color: #4e81ab; }
  .k__banner .k__tel__label > span {
    color: #4e81ab; }
  .k__banner .k__bton--link {
    color: #fff;
    margin-left: 1rem; }
  .k__banner .k__banner__action.--right {
    display: none; }
    @media (min-width: 800px) {
      .k__banner .k__banner__action.--right {
        display: flex; } }

.k__banner__title {
  color: #fff;
  font-weight: 500;
  margin: 0 0 0.5rem 0;
  line-height: 1.25;
  font-size: 1.25; }
  @media (min-width: 800px) {
    .k__banner__title {
      font-weight: 200;
      font-size: 2.5rem; } }

.k__banner__infos {
  right: 0;
  top: 50%; }

.k__banner__info {
  color: #fff; }
  .k__banner__info.--mini {
    font-size: 0.75rem;
    font-weight: bold; }

.k__nav--banner .k__tabs__nav__item {
  color: #fff; }

.k__hero {
  padding: 2rem 0; }

@media (min-width: 800px) {
  .k__banner__actions {
    display: flex;
    align-items: center; } }

.k__banner__action.--right {
  position: absolute;
  right: 1rem;
  bottom: 0;
  flex: 1;
  display: flex;
  justify-content: flex-end; }

@media (min-width: 800px) {
  .k__banner__action.--right {
    position: static; } }

.k__dates__pickers {
  margin-bottom: 2rem; }
  @media (min-width: 800px) {
    .k__dates__pickers {
      margin-right: 1rem;
      margin-bottom: 0; } }
  .k__dates__pickers .k__dates__picker .date-time-picker {
    position: absolute !important; }

.k__dates__picker {
  position: relative;
  margin-bottom: 0.75rem;
  font-size: 0.9rem; }
  .k__dates__picker:first-child {
    font-size: 1.25rem; }
  .k__dates__picker:last-child {
    margin: 0; }
  @media (min-width: 800px) {
    .k__dates__picker {
      display: flex;
      align-items: center; } }

.date-time-picker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0; }
  .date-time-picker .field {
    opacity: 0; }
    .date-time-picker .field .custom-button {
      display: none; }
  .date-time-picker .flex-1 {
    line-height: 1; }
  .date-time-picker .shortcut-button {
    padding: 0 0.5rem; }

.k__boxes {
  margin: 1.5vw 0; }

.k__boxes__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5vw; }

.k__boxes__more {
  line-height: 1; }

.k__box {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem; }
  .k__box > .k__kpis {
    margin: 0; }

.k__boxes__title {
  font-weight: bold; }

.k__boxes__more {
  font-weight: bold; }

.k__banner .k__boxes__title,
.k__banner .k__boxes__more {
  color: #fff; }

.k__social__bubble {
  color: #fff;
  font-size: 0.9em;
  display: flex;
  height: 2em;
  width: 2em;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }

.k__bton {
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  color: #fff;
  background-color: #4e81ab;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  transition: background-color 0.25s cubic-bezier(0, 0.85, 0.18, 0.99), opacity 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__bton em {
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    margin-left: 0.25rem; }
    .k__bton em::before {
      content: "( "; }
    .k__bton em::after {
      content: " )"; }
  .k__bton:hover {
    background-color: #acacac; }
  .k__bton:active, .k__bton:focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.2);
    outline: none; }
  .k__bton:disabled, .k__bton[disabled], .k__bton.--disabled {
    pointer-events: none;
    opacity: 0.25; }
  .k__bton.--hidden {
    background: none;
    color: inherit; }
  .k__bton.--full {
    width: 100%;
    justify-content: center; }
  .k__bton.--mini {
    font-size: 0.8rem;
    padding: 0.75rem 1.5rem; }
    .k__bton.--mini .k__icon {
      height: 1.25rem;
      width: 1.25rem; }
  .k__bton.--rounded {
    border-radius: 2rem; }
  .k__bton.--paginate {
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 90%); }
  .k__bton.--icon {
    padding: 0;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    color: inherit;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.35); }
    .k__bton.--icon.--active {
      box-shadow: none;
      background: #eee; }
    .k__bton.--icon.--rotate {
      animation: refresh 1s; }
    .k__bton.--icon.--light {
      background: #f4f7f8;
      box-shadow: none; }
      .k__bton.--icon.--light .k__icon {
        opacity: 0.75; }
    .k__bton.--icon .k__icon {
      margin: 0; }
    .k__bton.--icon.--nofill svg {
      fill: none; }
    .k__bton.--icon.--mini {
      height: 2rem;
      width: 2rem; }
      .k__bton.--icon.--mini .k__icon {
        height: 0.5rem;
        width: 0.5rem;
        opacity: 0.5; }
  .k__bton.--loading {
    position: relative;
    pointer-events: none; }
    .k__bton.--loading > span {
      opacity: 0; }
    .k__bton.--loading .k__loading__content {
      transform: none; }
    .k__bton.--loading .k__loading__svg__path {
      stroke: #fff; }
    .k__bton.--loading:active {
      box-shadow: none; }
  .k__bton.--primary {
    background-color: #4e81ab; }
  .k__bton.--secondary {
    background-color: #22e39e; }
  .k__bton.--dark {
    background-color: #545454; }
  .k__bton.--neutral {
    background: none;
    color: currentColor; }
    .k__bton.--neutral:hover {
      background: #f4f4f4; }
    .k__bton.--neutral.--active {
      color: #22e39e; }
  .k__bton .k__icon {
    height: 40%;
    width: 40%;
    margin-left: -0.5rem;
    margin-right: 0.5rem; }

.k__bton--link {
  position: relative;
  background: none;
  color: currentColor;
  padding: 0.25rem 0;
  opacity: 0.75;
  border: none; }
  .k__bton--link::before {
    opacity: 0.5;
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    border-bottom: dashed currentColor 1px; }
  .k__bton--link:hover, .k__bton--link:focus {
    background: none;
    color: #22e39e; }
  .k__bton--link.--loading .k__loading__svg__path {
    stroke: currentColor; }
  .k__bton--link.--disabled {
    pointer-events: none;
    opacity: 0.15; }

.k__link {
  position: relative;
  color: inherit;
  text-decoration: none;
  padding: 0.25rem 0;
  opacity: 0.75; }
  .k__link::before {
    opacity: 0.5;
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    border-bottom: dashed currentColor 1px; }
  .k__link:hover, .k__link:focus {
    color: #22e39e; }

.k__calendar {
  background: #fff;
  max-width: 100rem;
  padding: 1.618rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  margin: 1.618rem 0; }
  .k__calendar.--view-month .k__calendar__cells {
    border-top: solid #e8e8e8 1px;
    border-left: solid #e8e8e8 1px; }
  .k__calendar.--view-month .k__calendar__cell {
    border-right: solid #e8e8e8 1px;
    border-bottom: solid #e8e8e8 1px; }
    .k__calendar.--view-month .k__calendar__cell.--history .k__calendar__slot__content,
    .k__calendar.--view-month .k__calendar__cell.--history .k__calendar__slot__canals {
      opacity: 0.5;
      transition: opacity 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
    .k__calendar.--view-month .k__calendar__cell.--history .k__calendar__slot:hover .k__calendar__slot__content,
    .k__calendar.--view-month .k__calendar__cell.--history .k__calendar__slot:hover .k__calendar__slot__canals {
      opacity: 1; }
  .k__calendar.--view-week {
    border-top: solid #e8e8e8 1px; }
    .k__calendar.--view-week .k__calendar__day {
      margin: 0; }
    .k__calendar.--view-week .k__calendar__cells,
    .k__calendar.--view-week .k__calendar__days {
      padding-left: 6rem; }
    .k__calendar.--view-week .k__calendar__cell {
      padding-top: 0.25rem;
      position: relative;
      height: 100rem;
      text-align: center;
      border-right: solid #e8e8e8 1px; }
      .k__calendar.--view-week .k__calendar__cell .k__calendar__slot {
        position: absolute;
        left: 1rem;
        right: 1rem;
        z-index: 1; }

.k__calendar__content {
  position: relative; }

.k__calendar__header {
  font-size: 1.5rem;
  font-weight: bold; }

.k__calendar__days {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.k__calendar__day {
  text-align: center;
  flex: 0 0 14.28571%;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
  color: #b4b4b4;
  margin-bottom: 0.5rem; }

.k__calendar__captions {
  display: flex;
  margin: -0.5rem -0.5rem 2rem -0.5rem; }
  .k__calendar__captions .k__calendar__caption {
    padding-right: 0.75rem;
    margin: 0.5rem; }

.k__calendar__cells {
  display: flex;
  flex-flow: row wrap; }

.k__calendar__cell {
  flex: 0 0 14.28571%;
  min-height: 10rem;
  max-width: 14.28571%;
  padding: 1rem;
  animation: fade 0.4s; }
  .k__calendar__cell.--current-month .k__calendar__cell__date {
    opacity: 1; }

.k__calendar__cell__date {
  position: relative;
  user-select: none;
  display: block;
  opacity: 0.35;
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 0.5rem; }
  .k__calendar__cell__date.--is-closed {
    white-space: nowrap;
    overflow: hidden; }
    .k__calendar__cell__date.--is-closed::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1rem;
      background: white;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%); }

.k__calendar__cell__date__event {
  margin-left: 0.25rem;
  color: white;
  background: var(--color-hightlight2);
  font-weight: 400;
  font-size: 0.75rem;
  display: inline-flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  padding: 0 0.35rem;
  margin-bottom: 0.25rem; }
  .k__calendar__cell__date__event::first-letter {
    text-transform: uppercase; }
  .k__calendar__cell__date__event:last-child::after {
    content: none; }

.k__calendar__slot {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 0.9rem;
  font-weight: 600;
  color: #46505c;
  background: #e0e6ee;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  animation: appear 0.4s;
  overflow: hidden; }
  .k__calendar__slot.--published {
    background: #e2f5e3;
    color: #2d7732; }
  .k__calendar__slot.--scheduled {
    color: #318195;
    background: #d9f8ff; }
  .k__calendar__slot .k__calendar__slot__canals {
    margin: -1px -1px -1px 0.5rem; }
  .k__calendar__slot .k__social__bubble {
    font-size: 8px;
    margin: 1px;
    color: #fff; }

.k__calendar__slot__content {
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1; }

.k__calendar__slot__canals {
  display: flex;
  align-items: center;
  margin: 0 -0.15rem 0 0.5rem; }
  .k__calendar__slot__canals .k__icon {
    margin: 0 0.15rem; }

.k__calendar__timeline {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: 1px; }
  .k__calendar__timeline .k__calendar__timeline__line {
    display: block;
    height: 1px;
    background: #eee; }
  .k__calendar__timeline .k__calendar__timeline__desc {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 0.75rem;
    background: #eee;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    transform: translateY(-50%); }

.k__calendar__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 1rem 0;
  margin-left: -0.5rem; }
  .k__calendar__header .k__loading {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem; }
  .k__calendar__header .k__loading,
  .k__calendar__header .k__loading__content,
  .k__calendar__header .k__loading__svg {
    position: static;
    transform: none; }

.k__calendar__header__nav {
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer; }
  .k__calendar__header__nav .k__icon {
    width: 1.25rem;
    height: 1.25rem; }
  .k__calendar__header__nav.--prev {
    transform: rotateZ(180deg); }

.k__calendar__header__title {
  margin-left: 1rem; }

.k__calendar__cell {
  position: relative; }
  .k__calendar__cell.--today::after {
    content: "";
    display: block;
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 5px;
    width: 5px;
    background: #22e39e;
    border-radius: 50%;
    transform: translateY(-8px); }

.k__calendar__slot.--more {
  text-align: center;
  background: #f5f5f5; }
  .k__calendar__slot.--more .k__calendar__slot__content {
    opacity: 0.5; }

.k__calendar__action__area {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: solid #22e39e 1px;
  opacity: 0;
  z-index: 0; }
  .k__calendar__action__area:hover {
    opacity: 1; }
  .k__calendar__action__area.--disabled {
    opacity: 1;
    pointer-events: none;
    cursor: default;
    border: none;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23e8e8e8' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E"); }

.k__calendar__display__area {
  position: relative;
  z-index: 1; }

.k__calendar__line {
  position: relative; }

.k__canal {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  height: 100%; }
  .k__canal.--new {
    box-shadow: none;
    border: dashed #d7d7d7 1px; }

@media (min-width: 800px) {
  .k__canal__header {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

.k__canal__title {
  display: block;
  font-size: 1.14286rem;
  font-weight: 600;
  line-height: 1;
  margin: 1rem 1rem 0.5rem 1rem; }
  .k__canal__title .k__bton {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.k__canal__type {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 0.71429rem;
  color: #fff;
  font-weight: 600;
  background-color: #efefef;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 1rem 1rem 0.5rem 1rem; }
  .k__canal__type.--gmb {
    background-color: #4989f5; }
  .k__canal__type.--facebook {
    background-color: #405890; }
  .k__canal__type.--klixi {
    background-color: #2ef597; }
  .k__canal__type .k__icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; }

.k__canal__body {
  padding: 0.75rem 1rem 1rem 1rem; }

.k__canal__footer {
  border-top: solid #f1f1f1 1px;
  padding: 0.75rem 1rem; }

.k__canal__status__items {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: -0.25rem; }

.k__canal__status {
  margin: 0.25rem; }

.k__canal__tools {
  margin: 0 -0.75rem;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start; }
  .k__canal__tools .k__tool__link {
    padding: 0.25rem 0.75rem; }
    .k__canal__tools .k__tool__link::after {
      content: none; }
    .k__canal__tools .k__tool__link .k__icon {
      height: 1.25rem;
      width: 1.25rem; }

.k__canal__action {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%; }

.k__bton__confirmation {
  color: #fff;
  position: absolute;
  padding: 1rem;
  border-radius: 0.5rem;
  top: -1rem;
  left: 50%;
  background: #111;
  transform: translate(-50%, -100%);
  animation: fade 0.1s; }
  .k__bton__confirmation::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #111;
    transform: translateX(-50%); }
  .k__bton__confirmation .k__bton--link {
    margin-left: 0.25rem; }

.k__dashboard {
  margin-bottom: 1.5vw; }
  .k__dashboard .k__kpi__value {
    font-size: 2rem;
    font-weight: 700; }
  @media (min-width: 800px) {
    .k__dashboard {
      display: grid;
      grid-gap: 1.5vw;
      grid-template-columns: auto 40%; } }

.k__app .k__dates__picker {
  z-index: 99998; }
  .k__app .k__dates__picker.--current {
    z-index: 99999; }
  .k__app .k__dates__picker .datepicker-day-effect,
  .k__app .k__dates__picker .custom-button-effect {
    transition: none !important; }
  .k__app .k__dates__picker .datetimepicker {
    z-index: 99998; }
  .k__app .k__dates__picker .datepicker {
    padding: 1rem !important;
    border-radius: 0.5rem !important;
    box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.04), 0px 1px 4rem rgba(0, 0, 0, 0.06) !important;
    max-width: 100% !important;
    width: 32rem !important;
    min-width: 32rem !important; }
  .k__app .k__dates__picker .header-picker {
    background: #fff !important;
    color: inherit;
    padding: 1rem !important; }
    .k__app .k__dates__picker .header-picker .justify-content-between {
      font-weight: 600; }
  .k__app .k__dates__picker .shortcuts-container {
    width: 10rem !important;
    max-width: 100% !important;
    padding: 1rem !important; }
  @media (min-width: 800px) {
    .k__app .k__dates__picker .calendar {
      padding: 1rem !important;
      width: 20rem !important; } }
  .k__app .k__dates__picker .shortcut-button  {
    border: solid #eef3f6 1px !important; }
    .k__app .k__dates__picker .shortcut-button  .custom-button-effect {
      display: none; }
    .k__app .k__dates__picker .shortcut-button  .justify-content-center  {
      color: #22e39e !important; }
  .k__app .k__dates__picker .datepicker-buttons-container {
    padding: 0 !important; }
  .k__app .k__dates__picker .datepicker-button.validate {
    border: none !important;
    background: #4e81ab;
    width: 100%; }
    .k__app .k__dates__picker .datepicker-button.validate svg {
      display: none !important; }
    .k__app .k__dates__picker .datepicker-button.validate::before {
      content: "OK"; }
    .k__app .k__dates__picker .datepicker-button.validate .datepicker-button-effect {
      display: none !important; }

.custom-button[data-v-651b7c9a] {
  padding: 0 20px;
  position: relative;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  height: 30px;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #fff;
  font-weight: 500; }

.custom-button-content[data-v-651b7c9a] {
  position: relative; }

.custom-button svg[data-v-651b7c9a] {
  position: relative;
  fill: #1e90ff; }

.custom-button .custom-button-effect[data-v-651b7c9a],
.custom-button svg[data-v-651b7c9a] {
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.custom-button .custom-button-effect[data-v-651b7c9a] {
  position: absolute;
  background: #1e90ff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30px;
  border-radius: 4px;
  width: 100%;
  -webkit-transform: scale(0);
  transform: scale(0); }

.custom-button.with-border[data-v-651b7c9a] {
  border: 1px solid #eaeaea; }

.custom-button.is-hover[data-v-651b7c9a],
.custom-button[data-v-651b7c9a]:hover {
  border: 1px solid transparent !important; }

.custom-button.is-hover .custom-button-effect[data-v-651b7c9a],
.custom-button:hover .custom-button-effect[data-v-651b7c9a] {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.6; }

.custom-button.is-hover svg[data-v-651b7c9a],
.custom-button:hover svg[data-v-651b7c9a] {
  fill: #fff !important; }

.custom-button.is-hover .custom-button-content[data-v-651b7c9a],
.custom-button:hover .custom-button-content[data-v-651b7c9a] {
  color: #fff !important; }

.custom-button.is-selected[data-v-651b7c9a] {
  border: 1px solid transparent !important; }

.custom-button.is-selected .custom-button-effect[data-v-651b7c9a] {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.custom-button.is-selected svg[data-v-651b7c9a] {
  fill: #fff !important; }

.custom-button.is-selected .custom-button-content[data-v-651b7c9a] {
  color: #fff !important; }

.custom-button.is-dark[data-v-651b7c9a] {
  background-color: #424242; }

.custom-button.is-dark.with-border[data-v-651b7c9a] {
  border-color: #757575; }

.custom-button.is-dark svg[data-v-651b7c9a] {
  fill: #fff !important; }

.custom-button.round[data-v-651b7c9a] {
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%; }

.custom-button.round .custom-button-effect[data-v-651b7c9a] {
  border-radius: 50%;
  height: 24px; }

.field[data-v-74507c40] {
  position: relative;
  height: 100%; }

.field.is-dark .field-label[data-v-74507c40] {
  color: rgba(255, 255, 255, 0.7); }

.field.is-dark .field-input[data-v-74507c40] {
  background-color: #424242;
  border-color: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7); }

.field.is-dark.is-disabled .field-input[data-v-74507c40],
.field.is-dark.is-disabled .field-label[data-v-74507c40] {
  color: #000; }

.field-label[data-v-74507c40] {
  position: absolute;
  top: 5px;
  cursor: pointer;
  left: 13px;
  -webkit-transform: translateY(25%);
  transform: translateY(25%);
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54); }

.field-input[data-v-74507c40] {
  cursor: pointer;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  height: 42px;
  min-height: 42px;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 16px;
  z-index: 0; }

.field-input.no-clear-button[data-v-74507c40] {
  padding: 0 12px; }

.field-clear-button[data-v-74507c40] {
  position: absolute;
  right: 12px; }

.field.has-error .field-input[data-v-74507c40] {
  border-color: #ff4500; }

.field.has-error .field-label[data-v-74507c40] {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  font-size: 11px; }

.field.has-error .field-input[data-v-74507c40] {
  padding-top: 14px; }

.field.has-value .field-label[data-v-74507c40] {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  font-size: 11px; }

.field.has-value:not(.no-label) .field-input[data-v-74507c40] {
  padding-top: 14px; }

.field.is-focused .field-input[data-v-74507c40] {
  border-color: #1e90ff; }

.field.is-focused .field-label[data-v-74507c40] {
  color: #1e90ff; }

.field.is-disabled .field-input[data-v-74507c40] {
  border-color: #ccc;
  background: #f2f2f2; }

.field.is-disabled .field-input[data-v-74507c40],
.field.is-disabled .field-label[data-v-74507c40] {
  cursor: default; }

.field .text-danger[data-v-74507c40] {
  color: #ff4500; }

.field.is-dark[data-v-74507c40] ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7); }

.field.is-dark[data-v-74507c40] :-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1; }

.field.is-dark[data-v-74507c40] :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7); }

.field.is-dark[data-v-74507c40] ::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7); }

.field.is-dark[data-v-74507c40] ::placeholder {
  color: rgba(255, 255, 255, 0.7); }

.field.is-dark.is-disabled[data-v-74507c40] ::-webkit-input-placeholder {
  color: #424242; }

.field.is-dark.is-disabled[data-v-74507c40] :-moz-placeholder {
  color: #424242;
  opacity: 1; }

.field.is-dark.is-disabled[data-v-74507c40] :-ms-input-placeholder {
  color: #424242; }

.field.is-dark.is-disabled[data-v-74507c40] ::-ms-input-placeholder {
  color: #424242; }

.field.is-dark.is-disabled[data-v-74507c40] ::placeholder {
  color: #424242; }

.field.sm .field-input[data-v-74507c40] {
  height: 36px;
  min-height: 36px;
  font-size: 12px; }

.field.sm .field-label[data-v-74507c40] {
  font-size: 10px; }

.field.sm.has-value:not(.no-label) .field-input[data-v-74507c40] {
  padding-top: 12px; }

.field.lg .field-input[data-v-74507c40] {
  height: 48px;
  min-height: 48px;
  font-size: 16px; }

.field.lg .field-label[data-v-74507c40] {
  font-size: 14px; }

.field.lg.has-value:not(.no-label) .field-input[data-v-74507c40] {
  padding-top: 16px; }

.shortcuts-container[data-v-9b117170] {
  width: 140px;
  max-width: 140px;
  min-width: 140px;
  padding: 10px 5px;
  border-right: 1px solid #eaeaea;
  overflow: auto; }

.shortcuts-container button.shortcut-button[data-v-9b117170] {
  margin-bottom: 10px;
  width: 100%; }

.shortcuts-container.is-dark[data-v-9b117170] {
  border-color: #757575; }

@media screen and (max-width: 415px) {
  .shortcuts-container[data-v-9b117170]:not(.inline) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-width: 100vw;
    min-width: 100vw;
    border-right: 0;
    border-bottom: 1px solid #eaeaea;
    height: 52px !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    overflow-y: hidden; }
  .shortcuts-container:not(.inline) .shortcut-button[data-v-9b117170] {
    margin-bottom: 0; }
  .shortcuts-container:not(.inline)
.shortcut-button[data-v-9b117170]:not(:last-child) {
    margin-right: 10px; }
  .shortcuts-container.is-dark[data-v-9b117170] {
    border-color: #757575; } }

.year-month-selector[data-v-4a0f7afa] {
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #424242;
  padding: 10px; }

.year-month-selector.dark[data-v-4a0f7afa] {
  color: #fff;
  background-color: #424242; }

.year-month-selector .month-button[data-v-4a0f7afa] {
  text-transform: capitalize; }

.week-days[data-v-a5a27e8c] {
  height: 41px;
  text-transform: capitalize; }

.week-days.is-dark .week-days-container[data-v-a5a27e8c] {
  color: #a8a8a8 !important; }

@media screen and (max-width: 415px) {
  :not(.inline) .datepicker-week[data-v-a5a27e8c] {
    height: 21px !important; } }

.datepicker-container[data-v-628b1b6b] {
  width: 260px;
  padding: 0 5px;
  position: relative; }

.datepicker-container.range.has-shortcuts[data-v-628b1b6b] {
  width: 400px; }

.datepicker-container.p-0[data-v-628b1b6b] {
  padding: 0; }

.datepicker-container .padding-button[data-v-628b1b6b] {
  padding: 5px 3px !important; }

.datepicker-container .calendar[data-v-628b1b6b] {
  position: relative; }

.datepicker-container .datepicker-controls[data-v-628b1b6b] {
  height: 56px; }

.datepicker-container .datepicker-controls .arrow-month[data-v-628b1b6b] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px; }

.datepicker-container .datepicker-controls .datepicker-button[data-v-628b1b6b] {
  background: transparent;
  cursor: pointer;
  padding: 0 10px;
  border: none;
  outline: none; }

.datepicker-container
.datepicker-controls
.datepicker-button
svg[data-v-628b1b6b] {
  height: 17px;
  width: 17px;
  fill: #2c3e50; }

.datepicker-container
.datepicker-controls
.datepicker-button.datepicker-prev[data-v-628b1b6b] {
  text-align: left !important; }

.datepicker-container
.datepicker-controls
.datepicker-button.datepicker-next[data-v-628b1b6b] {
  text-align: right !important; }

.datepicker-container
.datepicker-controls
.datepicker-container-label[data-v-628b1b6b] {
  text-transform: capitalize;
  font-size: 16px;
  position: relative;
  height: 56px;
  overflow: hidden; }

.datepicker-container .datepicker-controls .date-buttons[data-v-628b1b6b] {
  text-transform: capitalize;
  font-weight: 400; }

.datepicker-container .month-container[data-v-628b1b6b] {
  position: relative;
  overflow: hidden; }

.datepicker-container .datepicker-days[data-v-628b1b6b] {
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  overflow: hidden;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap; }

.datepicker-container .datepicker-days .datepicker-day[data-v-628b1b6b] {
  height: 41px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 14.28571%;
  position: relative;
  border: none;
  background: transparent;
  font-size: 13px;
  outline: none; }

.datepicker-container .datepicker-days .datepicker-day.enable[data-v-628b1b6b] {
  cursor: pointer; }

.datepicker-container .datepicker-days .datepicker-day-effect[data-v-628b1b6b],
.datepicker-container
.datepicker-days
.datepicker-day
.datepicker-today[data-v-628b1b6b] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.datepicker-container
.datepicker-days
.datepicker-day
.datepicker-day-effect[data-v-628b1b6b] {
  margin: auto;
  opacity: 0.6;
  background: #1e90ff;
  -webkit-transform: scale(0);
  transform: scale(0); }

.datepicker-container
.datepicker-days
.datepicker-day
.datepicker-today[data-v-628b1b6b] {
  background-color: #eaeaea; }

.datepicker-container
.datepicker-days
.datepicker-day
.datepicker-day-text[data-v-628b1b6b] {
  position: relative;
  color: #000; }

.datepicker-container
.datepicker-days
.datepicker-day
.datepicker-day-keyboard-selected[data-v-628b1b6b] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 26px;
  width: 26px;
  opacity: 0.7;
  border-radius: 50%;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: #afafaf; }

.datepicker-container
.datepicker-days
.datepicker-day:hover
.datepicker-day-text[data-v-628b1b6b] {
  color: #fff; }

.datepicker-container
.datepicker-days
.datepicker-day:hover
.datepicker-day-effect[data-v-628b1b6b] {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.6; }

.datepicker-container
.datepicker-days
.datepicker-day.between
.datepicker-day-text[data-v-628b1b6b] {
  color: #fff; }

.datepicker-container
.datepicker-days
.datepicker-day.between
.datepicker-day-effect[data-v-628b1b6b] {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.5;
  border-radius: 0;
  width: 100%; }

.datepicker-container
.datepicker-days
.datepicker-day.between.first
.datepicker-day-effect[data-v-628b1b6b] {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.datepicker-container
.datepicker-days
.datepicker-day.between.last
.datepicker-day-effect[data-v-628b1b6b] {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.datepicker-container
.datepicker-days
.datepicker-day.between
.datepicker-day-keyboard-selected[data-v-628b1b6b],
.datepicker-container
.datepicker-days
.datepicker-day.between.first
.datepicker-day-keyboard-selected[data-v-628b1b6b],
.datepicker-container
.datepicker-days
.datepicker-day.between.last
.datepicker-day-keyboard-selected[data-v-628b1b6b] {
  background-color: rgba(0, 0, 0, 0.66); }

.datepicker-container
.datepicker-days
.datepicker-day.selected
.datepicker-day-text[data-v-628b1b6b] {
  color: #fff;
  font-weight: 700; }

.datepicker-container
.datepicker-days
.datepicker-day.selected
.datepicker-day-effect[data-v-628b1b6b] {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.datepicker-container
.datepicker-days
.datepicker-day.selected
.datepicker-day-keyboard-selected[data-v-628b1b6b] {
  background-color: rgba(0, 0, 0, 0.66); }

.datepicker-container
.datepicker-days
.datepicker-day.disabled
.datepicker-day-text[data-v-628b1b6b] {
  color: #ccc; }

.datepicker-container
.datepicker-days
.datepicker-day.disabled.selected[data-v-628b1b6b] {
  color: #fff; }

.datepicker-container
.datepicker-days
.datepicker-day.disabled
.datepicker-day-effect[data-v-628b1b6b] {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0; }

.datepicker-container.is-dark
.datepicker-days
.datepicker-day:not(.between):not(.selected)
.datepicker-day-text[data-v-628b1b6b] {
  color: #fff; }

.datepicker-container.is-dark
.datepicker-days
.datepicker-day:not(.between):not(.selected).disabled
.datepicker-day-text[data-v-628b1b6b] {
  color: #757575; }

.datepicker-container.is-dark .datepicker-label[data-v-628b1b6b] {
  color: #fff; }

.datepicker-container.is-dark .text-muted[data-v-628b1b6b] {
  color: #a8a8a8 !important; }

.datepicker-container.is-dark .datepicker-button svg[data-v-628b1b6b] {
  fill: #fff; }

.datepicker-container.is-dark .datepicker-today[data-v-628b1b6b] {
  background-color: #292929 !important; }

@media screen and (max-width: 415px) {
  .datepicker-container[data-v-628b1b6b] {
    width: 100%;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-flex-flow: column;
    flex-flow: column;
    -moz-flex-direction: column; }
  .datepicker-container:not(.inline) .datepicker-controls[data-v-628b1b6b] {
    height: 36px !important; }
  .datepicker-container.range.has-shortcuts[data-v-628b1b6b] {
    width: 100%; } }

.time-picker-column[data-v-683a4ef0]::-webkit-scrollbar {
  display: none; }

.time-picker[data-v-683a4ef0] {
  width: 160px;
  max-width: 160px;
  position: relative;
  z-index: 1; }

.time-picker.inline[data-v-683a4ef0] {
  width: 100%;
  max-width: 100%; }

.time-picker[data-v-683a4ef0]:after,
.time-picker[data-v-683a4ef0]:before {
  content: "";
  top: 50%;
  position: absolute;
  margin: 0 auto;
  margin-top: -14px;
  height: 30px;
  z-index: -1;
  width: 85%;
  left: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }

.time-picker-column[data-v-683a4ef0] {
  position: relative;
  overflow-y: auto; }

.time-picker-column-item[data-v-683a4ef0] {
  height: 28px;
  min-height: 28px;
  padding: 0;
  color: #252525;
  cursor: pointer;
  position: relative;
  border: none;
  background: transparent;
  font-size: 13px;
  width: 100%;
  outline: none; }

.time-picker-column-item-effect[data-v-683a4ef0] {
  position: absolute;
  opacity: 0.6;
  background: #1e90ff;
  height: 24px;
  width: 70%;
  top: 2px;
  left: 15%;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -webkit-transform: scale(0);
  transform: scale(0);
  border-radius: 4px; }

.time-picker-column-item-effect[data-v-683a4ef0]:hover {
  -webkit-transform: scale(1);
  transform: scale(1); }

.time-picker-column-item-text[data-v-683a4ef0] {
  position: relative; }

.time-picker-column-item:hover .time-picker-column-item-text[data-v-683a4ef0] {
  color: #fff;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms; }

.time-picker-column-item:hover
.time-picker-column-item-effect[data-v-683a4ef0] {
  -webkit-transform: scale(1);
  transform: scale(1); }

.time-picker-column-item.active[data-v-683a4ef0] {
  color: #fff;
  font-weight: 700; }

.time-picker-column-item.active
.time-picker-column-item-effect[data-v-683a4ef0] {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.time-picker-column-item.disabled
.time-picker-column-item-text[data-v-683a4ef0] {
  color: #ccc; }

.time-picker-column-item.disabled
.time-picker-column-item-text[data-v-683a4ef0]:hover {
  color: #ccc !important; }

.time-picker-column-item.disabled
.time-picker-column-item-effect[data-v-683a4ef0] {
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
  opacity: 0 !important; }

.time-picker-column-item.disabled.active
.time-picker-column-item-effect[data-v-683a4ef0] {
  background-color: #eaeaea !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  opacity: 1 !important; }

.time-picker.with-border[data-v-683a4ef0] {
  border-left: 1px solid #eaeaea; }

.time-picker.with-border.is-dark[data-v-683a4ef0] {
  border-left: 1px solid #757575; }

.time-picker.is-dark .time-picker-column-item-text[data-v-683a4ef0] {
  color: #fff; }

@media screen and (max-width: 415px) {
  .time-picker.inline[data-v-683a4ef0] {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    border-left: none; }
  .time-picker[data-v-683a4ef0]:not(.inline) {
    border: 0;
    border-top: 1px solid #eaeaea;
    width: 100%;
    max-width: 100%;
    height: unset !important;
    overflow: hidden; }
  .time-picker:not(.inline).dark[data-v-683a4ef0] {
    border-top: 1px solid #757575; }
  .timepicker-container.is-dark[data-v-683a4ef0] {
    border-color: #757575; } }

.header-picker[data-v-6d49f11d] {
  background: #fff;
  border-bottom: 1px solid #eaeaea;
  color: #fff;
  position: relative; }

.header-picker-year[data-v-6d49f11d] {
  opacity: 0.7;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  height: 14px; }

.header-picker-date[data-v-6d49f11d],
.header-picker-hour[data-v-6d49f11d],
.header-picker-minute[data-v-6d49f11d],
.header-picker-range[data-v-6d49f11d],
.header-picker-time[data-v-6d49f11d] {
  font-size: 18px;
  line-height: 18px;
  position: relative;
  height: 18px; }

.header-picker-date[data-v-6d49f11d] {
  text-transform: capitalize; }

.header-picker-hour.twelve[data-v-6d49f11d] {
  min-width: 74px; }

.header-picker .pl-10[data-v-6d49f11d] {
  padding-left: 10px; }

.header-picker .time-number[data-v-6d49f11d] {
  width: 22px; }

.header-picker.is-dark[data-v-6d49f11d] {
  border: 0;
  color: #fff !important; }

.datepicker-buttons-container[data-v-601c6e79] {
  padding: 5px;
  border-top: 1px solid #eaeaea;
  background-color: #fff;
  z-index: 1;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.datepicker-buttons-container .datepicker-button[data-v-601c6e79] {
  padding: 0 20px;
  position: relative;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  height: 30px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #fff;
  font-weight: 500; }

.datepicker-buttons-container .datepicker-button-content[data-v-601c6e79] {
  position: relative; }

.datepicker-buttons-container .datepicker-button svg[data-v-601c6e79] {
  position: relative;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  fill: #00c853; }

.datepicker-buttons-container
.datepicker-button
.datepicker-button-effect[data-v-601c6e79] {
  position: absolute;
  background: #00c853;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 30px;
  border-radius: 4px;
  width: 100%;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -webkit-transform: scale(0);
  transform: scale(0); }

.datepicker-buttons-container .datepicker-button[data-v-601c6e79]:hover {
  border: 1px solid transparent; }

.datepicker-buttons-container
.datepicker-button:hover
.datepicker-button-effect[data-v-601c6e79] {
  -webkit-transform: scale(1);
  transform: scale(1); }

.datepicker-buttons-container .datepicker-button:hover svg[data-v-601c6e79] {
  fill: #fff !important; }

.datepicker-buttons-container
.datepicker-button:hover
.datepicker-button-content[data-v-601c6e79] {
  color: #fff !important; }

.datepicker-buttons-container
.datepicker-button.now.right-margin[data-v-601c6e79] {
  margin-right: 10px; }

.datepicker-buttons-container
.datepicker-button.now
.datepicker-button-content[data-v-601c6e79] {
  color: #1e90ff; }

.datepicker-buttons-container
.datepicker-button.now
.datepicker-button-effect[data-v-601c6e79] {
  background: #1e90ff; }

.datepicker-buttons-container .datepicker-button.validate[data-v-601c6e79] {
  border: 1px solid #eaeaea; }

.datepicker-buttons-container.is-dark .datepicker-button[data-v-601c6e79],
.datepicker-buttons-container.is-dark[data-v-601c6e79] {
  background-color: #424242; }

.datepicker-buttons-container.is-dark
.datepicker-button[data-v-601c6e79]:not(.now),
.datepicker-buttons-container.is-dark[data-v-601c6e79]:not(.now) {
  border-color: #757575; }

.datepicker-buttons-container.is-dark .datepicker-button svg[data-v-601c6e79],
.datepicker-buttons-container.is-dark svg[data-v-601c6e79] {
  fill: #fff !important; }

.datetimepicker[data-v-6e81f291] {
  position: absolute;
  z-index: 9;
  width: 100%; }

.datetimepicker.visible[data-v-6e81f291] {
  z-index: 999; }

.datetimepicker .datepicker[data-v-6e81f291] {
  position: absolute;
  z-index: 5;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  max-width: 400px; }

.datetimepicker .datepicker .pickers-container[data-v-6e81f291] {
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.datetimepicker .datepicker.right[data-v-6e81f291] {
  right: 0; }

.datetimepicker.is-dark .datepicker[data-v-6e81f291],
.datetimepicker.is-dark .pickers-container[data-v-6e81f291] {
  background: #424242;
  border: 0; }

.inline .datepicker[data-v-6e81f291],
.inline.datetimepicker[data-v-6e81f291] {
  position: relative; }

.inline .datepicker[data-v-6e81f291] {
  margin-bottom: 0 !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  width: 100%;
  max-width: 100%;
  background-color: #fff; }

@media screen and (max-width: 415px) {
  .pickers-container[data-v-6e81f291] {
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -ms-flex-flow: column;
    flex-flow: column;
    -moz-flex-direction: column;
    height: 80%; }
  .datepicker-container[data-v-6e81f291] {
    width: 100%; }
  .datepicker-container.has-shortcuts[data-v-6e81f291] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .datetimepicker[data-v-6e81f291]:not(.inline) {
    margin: 0 !important;
    position: absolute;
    top: 0 !important;
    bottom: 0;
    right: 0;
    left: 0; }
  .datetimepicker:not(.inline) .datepicker[data-v-6e81f291] {
    border-radius: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    max-width: inherit !important;
    min-width: inherit !important;
    position: fixed;
    height: 100%;
    margin: 0 !important; } }

.date-time-picker {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50; }

.date-time-picker,
.date-time-picker input,
.date-time-picker label,
.date-time-picker p,
.date-time-picker span {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }

.date-time-picker .fluid {
  width: 100%; }

.date-time-picker .fill-height {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%; }

.date-time-picker .spacer {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.date-time-picker .align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.date-time-picker .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.date-time-picker .flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: start;
  -moz-box-align: start;
  -moz-box-pack: start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start; }

.date-time-picker .flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
  -moz-box-align: end;
  -moz-box-pack: end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end; }

.date-time-picker .flex-direction-column {
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-flow: column;
  flex-flow: column;
  -moz-flex-direction: column; }

.date-time-picker .flex-direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
  -ms-flex-flow: column-reverse;
  flex-flow: column-reverse;
  -moz-flex-direction: column-reverse; }

.date-time-picker .flex-direction-row {
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -moz-flex-direction: row; }

.date-time-picker .justify-content-end,
.date-time-picker .justify-content-right {
  justify-content: flex-end;
  -ms-flex-pack: end;
  -moz-box-align: end;
  -moz-box-pack: end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -webkit-box-align: end; }

.date-time-picker .justify-content-center {
  justify-content: center;
  -ms-flex-pack: center;
  -moz-box-align: center;
  -moz-box-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center; }

.date-time-picker .justify-content-between {
  justify-content: space-between; }

.date-time-picker .justify-content-around,
.date-time-picker .justify-content-between {
  -ms-flex-pack: justify;
  -moz-box-align: stretch;
  -moz-box-pack: justify;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -webkit-box-align: stretch; }

.date-time-picker .justify-content-around {
  justify-content: space-around; }

.date-time-picker .flex-fill {
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto; }

.date-time-picker .flex-fixed {
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto; }

.date-time-picker .flex-1 {
  -webkit-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.date-time-picker .flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.date-time-picker .flex-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.date-time-picker .lm-fs-12 {
  font-size: 12px !important; }

.date-time-picker .lm-fs-14 {
  font-size: 14px !important; }

.date-time-picker .lm-fs-16 {
  font-size: 16px !important; }

.date-time-picker .lm-fs-18 {
  font-size: 18px !important; }

.date-time-picker .lm-fw-300 {
  font-weight: 300; }

.date-time-picker .lm-fw-400 {
  font-weight: 400; }

.date-time-picker .lm-fw-500 {
  font-weight: 500; }

.date-time-picker .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .date-time-picker .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .date-time-picker .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .date-time-picker .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .date-time-picker .container {
    max-width: 1140px; } }

.date-time-picker .lm-pr-1 {
  padding-right: 0.25rem !important; }

.date-time-picker .lm-pt-1 {
  padding-top: 0.25rem !important; }

.date-time-picker .lm-pb-1 {
  padding-bottom: 0.25rem !important; }

.date-time-picker .lm-pl-1,
.date-time-picker .lm-px-1 {
  padding-left: 0.25rem !important; }

.date-time-picker .lm-px-1 {
  padding-right: 0.25rem !important; }

.date-time-picker .lm-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.date-time-picker .lm-p-1 {
  padding: 0.25rem !important; }

.date-time-picker .lm-pr-2 {
  padding-right: 0.5rem !important; }

.date-time-picker .lm-pt-2 {
  padding-top: 0.5rem !important; }

.date-time-picker .lm-pb-2 {
  padding-bottom: 0.5rem !important; }

.date-time-picker .lm-pl-2,
.date-time-picker .lm-px-2 {
  padding-left: 0.5rem !important; }

.date-time-picker .lm-px-2 {
  padding-right: 0.5rem !important; }

.date-time-picker .lm-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.date-time-picker .lm-p-2 {
  padding: 0.5rem !important; }

.date-time-picker .lm-pr-3 {
  padding-right: 1rem !important; }

.date-time-picker .lm-pt-3 {
  padding-top: 1rem !important; }

.date-time-picker .lm-pb-3 {
  padding-bottom: 1rem !important; }

.date-time-picker .lm-pl-3,
.date-time-picker .lm-px-3 {
  padding-left: 1rem !important; }

.date-time-picker .lm-px-3 {
  padding-right: 1rem !important; }

.date-time-picker .lm-py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.date-time-picker .lm-p-3 {
  padding: 1rem !important; }

.date-time-picker .lm-pr-4 {
  padding-right: 1.5rem !important; }

.date-time-picker .lm-pt-4 {
  padding-top: 1.5rem !important; }

.date-time-picker .lm-pb-4 {
  padding-bottom: 1.5rem !important; }

.date-time-picker .lm-pl-4,
.date-time-picker .lm-px-4 {
  padding-left: 1.5rem !important; }

.date-time-picker .lm-px-4 {
  padding-right: 1.5rem !important; }

.date-time-picker .lm-py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.date-time-picker .lm-p-4 {
  padding: 1.5rem !important; }

.date-time-picker .lm-pr-5 {
  padding-right: 3rem !important; }

.date-time-picker .lm-pt-5 {
  padding-top: 3rem !important; }

.date-time-picker .lm-pb-5 {
  padding-bottom: 3rem !important; }

.date-time-picker .lm-pl-5,
.date-time-picker .lm-px-5 {
  padding-left: 3rem !important; }

.date-time-picker .lm-px-5 {
  padding-right: 3rem !important; }

.date-time-picker .lm-py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.date-time-picker .lm-p-5 {
  padding: 3rem !important; }

.date-time-picker .lm-mr-1 {
  margin-right: 0.25rem !important; }

.date-time-picker .lm-mt-1 {
  margin-top: 0.25rem !important; }

.date-time-picker .lm-mb-1 {
  margin-bottom: 0.25rem !important; }

.date-time-picker .lm-ml-1,
.date-time-picker .lm-mx-1 {
  margin-left: 0.25rem !important; }

.date-time-picker .lm-mx-1 {
  margin-right: 0.25rem !important; }

.date-time-picker .lm-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.date-time-picker .lm-m-1 {
  margin: 0.25rem !important; }

.date-time-picker .lm-mr-2 {
  margin-right: 0.5rem !important; }

.date-time-picker .lm-mt-2 {
  margin-top: 0.5rem !important; }

.date-time-picker .lm-mb-2 {
  margin-bottom: 0.5rem !important; }

.date-time-picker .lm-ml-2,
.date-time-picker .lm-mx-2 {
  margin-left: 0.5rem !important; }

.date-time-picker .lm-mx-2 {
  margin-right: 0.5rem !important; }

.date-time-picker .lm-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.date-time-picker .lm-m-2 {
  margin: 0.5rem !important; }

.date-time-picker .lm-mr-3 {
  margin-right: 1rem !important; }

.date-time-picker .lm-mt-3 {
  margin-top: 1rem !important; }

.date-time-picker .lm-mb-3 {
  margin-bottom: 1rem !important; }

.date-time-picker .lm-ml-3 {
  margin-left: 1rem !important; }

.date-time-picker .lm-mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.date-time-picker .lm-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.date-time-picker .lm-m-3 {
  margin: 1rem !important; }

.date-time-picker .lm-mr-4 {
  margin-right: 1.5rem !important; }

.date-time-picker .lm-mt-4 {
  margin-top: 1.5rem !important; }

.date-time-picker .lm-mb-4 {
  margin-bottom: 1.5rem !important; }

.date-time-picker .lm-ml-4,
.date-time-picker .lm-mx-4 {
  margin-left: 1.5rem !important; }

.date-time-picker .lm-mx-4 {
  margin-right: 1.5rem !important; }

.date-time-picker .lm-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.date-time-picker .lm-m-4 {
  margin: 1.5rem !important; }

.date-time-picker .lm-mr-5 {
  margin-right: 3rem !important; }

.date-time-picker .lm-mt-5 {
  margin-top: 3rem !important; }

.date-time-picker .lm-mb-5 {
  margin-bottom: 3rem !important; }

.date-time-picker .lm-ml-5 {
  margin-left: 3rem !important; }

.date-time-picker .lm-mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important; }

.date-time-picker .lm-my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.date-time-picker .lm-m-5 {
  margin: 3rem !important; }

.date-time-picker .lm-btn {
  padding: 10px 20px;
  margin-bottom: 20px;
  border: none;
  display: inline-block;
  border-radius: 4px;
  text-decoration: none;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #1e90ff;
  color: #fff;
  font-weight: 500; }

.date-time-picker .lm-btn:hover {
  background-color: #0077ea;
  -webkit-box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5); }

.date-time-picker .lm-btn.option {
  background-color: #424242; }

.date-time-picker .lm-btn.option:hover {
  background-color: #292929; }

.date-time-picker .lm-btn-success {
  background-color: #9acd32; }

.date-time-picker .lm-btn-success:hover {
  background-color: #7ba428; }

.date-time-picker .lm-btn-dark {
  background-color: #424242; }

.date-time-picker .lm-btn-dark:hover {
  background-color: #292929; }

.date-time-picker .lm-btn-danger {
  background-color: #ff4500; }

.date-time-picker .lm-btn-danger:hover {
  background-color: #cc3700; }

.date-time-picker .dark .lm-btn:hover {
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.6), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.6), 0 2px 4px 0 rgba(0, 0, 0, 0.5); }

.date-time-picker .dark .lm-btn.option {
  background-color: #424242; }

.date-time-picker .dark .lm-btn.option:hover {
  background-color: #5c5c5c; }

.date-time-picker .slide-enter-active,
.date-time-picker .slide-leave-active {
  opacity: 1;
  z-index: 998;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.date-time-picker .slide-enter,
.date-time-picker .slide-leave-to {
  opacity: 0;
  z-index: 998;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px); }

.date-time-picker .slideinvert-enter-active,
.date-time-picker .slideinvert-leave-active {
  opacity: 1;
  z-index: 998;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.date-time-picker .slideinvert-enter,
.date-time-picker .slideinvert-leave-to {
  opacity: 0;
  z-index: 998;
  -webkit-transform: translateY(40px);
  transform: translateY(40px); }

.date-time-picker .slidenext-enter-active,
.date-time-picker .slidenext-leave-active,
.date-time-picker .slideprev-enter-active,
.date-time-picker .slideprev-leave-active {
  position: absolute;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.date-time-picker .slidenext-enter,
.date-time-picker .slideprev-leave-to {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.date-time-picker .slidenext-leave-to,
.date-time-picker .slideprev-enter {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.date-time-picker .slidevnext-enter-active,
.date-time-picker .slidevnext-leave-active,
.date-time-picker .slidevprev-enter-active,
.date-time-picker .slidevprev-leave-active {
  position: absolute;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.date-time-picker .slidevnext-enter,
.date-time-picker .slidevprev-leave-to {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0; }

.date-time-picker .slidevnext-leave-to,
.date-time-picker .slidevprev-enter {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0; }

@media screen and (max-width: 415px) {
  .date-time-picker .slide-enter-active,
  .date-time-picker .slide-leave-active,
  .date-time-picker .slideinvert-enter-active,
  .date-time-picker .slideinvert-leave-active {
    -webkit-transition: all 0s;
    transition: all 0s; } }

.date-time-picker .lm-text-white {
  color: #fff; }

.date-time-picker .lm-dots-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.date-time-picker .lm-text-muted {
  color: rgba(0, 0, 0, 0.54) !important; }

.date-time-picker .lm-text-strong {
  font-weight: 500; }

.date-time-picker .lm-text-center {
  text-align: center !important; }

.date-time-picker .lm-text-left {
  text-align: left !important; }

.date-time-picker .lm-text-right {
  text-align: right !important; }

.date-time-picker .lm-h-100 {
  height: 100% !important; }

.date-time-picker .lm-mh-100 {
  max-height: 100% !important; }

.date-time-picker .lm-w-100 {
  width: 100% !important; }

.date-time-picker .lm-mw-100 {
  max-width: 100% !important; }

.date-time-picker *,
.date-time-picker :after,
.date-time-picker :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.date-time-picker {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
  border-radius: 4px;
  position: relative; }

.date-time-picker .time-picker-overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4); }

@media screen and (max-width: 415px) {
  .time-picker-overlay {
    display: none; }
  .date-time-picker:not(.inline) {
    position: inherit !important; } }

.datepicker-button.now {
  display: none !important; }

@media (orientation: landscape) and (max-device-width: 812px) {
  .k__app .k__dates__picker .datepicker {
    width: 21rem !important;
    min-width: 21rem !important; }
  .k__app .k__dates__picker .calendar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-width: 0 !important; }
  .k__app .k__dates__picker .header-picker,
  .k__app .k__dates__picker .shortcuts-container {
    display: none; }
  .k__app .k__dates__picker .has-shortcuts {
    width: 200px !important; }
  .k__app .k__dates__picker .datepicker-days .datepicker-day {
    height: 2.5rem; }
  .k__app .k__dates__picker .month-container {
    height: 13rem !important; }
  .k__app .k__dates__picker .datepicker-container-label,
  .k__app .k__dates__picker .datepicker-controls {
    height: auto !important; }
    .k__app .k__dates__picker .datepicker-container-label .custom-button,
    .k__app .k__dates__picker .datepicker-controls .custom-button {
      font-size: 12px !important; } }

.k__date__fragment {
  display: inline-block;
  margin: 0 0.25em 0 0;
  color: #3f444a;
  color: #fff;
  line-height: 1.5; }
  .k__date__fragment.--strong {
    pointer-events: none;
    position: relative;
    font-weight: 800; }
    .k__date__fragment.--strong::after {
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      bottom: 0;
      border-bottom: dashed white 1px; }
  @media (min-width: 800px) {
    .k__date__fragment.--strong {
      font-size: 1.25em; } }

.k__dropdown {
  padding: 0.5em 1em;
  margin: 0;
  list-style: none;
  background: #fff;
  box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.04), 0px 1px 4rem rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem; }
  .k__dropdown.--checkboxes .k__dropdown__link {
    position: relative;
    padding-left: 1.75rem; }
    .k__dropdown.--checkboxes .k__dropdown__link::before {
      content: "";
      display: block;
      position: absolute;
      height: 1rem;
      width: 1rem;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2px;
      border: solid #ddd 1px; }
    .k__dropdown.--checkboxes .k__dropdown__link::after {
      padding: 0;
      opacity: 0.75;
      content: "";
      display: block;
      position: absolute;
      height: 0.7rem;
      width: 0.7rem;
      left: 0.3rem;
      top: 50%;
      transform-origin: center center;
      transition: transform 0.1s;
      transform: translateY(-50%) scale(0);
      background: url(../icon/check.svg) no-repeat; }
    .k__dropdown.--checkboxes .k__dropdown__link.--active::after {
      transform: translateY(-50%) scale(1); }

.k__dropdown__item {
  border-top: solid #ebeced 1px; }
  .k__dropdown__item:first-child {
    border: none; }

.k__dropdown__link {
  font-size: 0.9rem;
  text-decoration: none;
  color: currentColor;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  white-space: nowrap; }
  .k__dropdown__link .k__icon {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.75em; }
  .k__dropdown__link:hover, .k__dropdown__link:focus {
    outline: none;
    color: #22e39e; }

.emoji-picker {
  background: #fff !important;
  border: none !important;
  z-index: 9;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }
  .emoji-picker #Categories {
    background: #fff !important;
    border: none !important; }

.page-reportingswaze .k__table__col__title.--adName,
.page-reportingsfacebook .k__table__col__title.--adName {
  font-size: 1rem;
  font-weight: bold; }

.page-reportingsadwords .k__wrapper__error {
  top: 4rem; }

@media (max-width: 1025px) {
  .page-reportingsreports .k__table th {
    border: none; }
  .page-reportingsreports .k__table .k__table__header {
    display: block; }
  .page-reportingsreports .k__table .k__table__col {
    text-align: left !important; } }

.k__featured {
  margin: 2rem auto; }
  .k__featured .k__h3 {
    font-size: 1.5rem;
    margin-top: -0.5rem; }
  .k__featured .k__p {
    color: #808080;
    margin: 0; }
  @media (min-width: 800px) {
    .k__featured {
      display: flex;
      align-items: center; }
      .k__featured .k__featured__desc {
        flex: 0 0 33.33333%; }
      .k__featured .k__featured__content {
        flex: 0 0 66.66667%; } }

.k__featured__desc {
  padding-right: 4rem; }
  .k__featured__desc .k__icon {
    color: #4e81ab; }

.k__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: solid #eef3f6 2px;
  height: 15rem; }
  .k__footer .k__logo {
    display: inline-block;
    width: 5rem;
    opacity: .75;
    transition: opacity 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
    .k__footer .k__logo:hover {
      opacity: 1; }
  @media (min-width: 800px) {
    .k__footer {
      height: 9rem; } }

.k__footer__items {
  color: #a8b5b9;
  text-align: center;
  padding: 2rem 0;
  font-size: .75em; }
  @media (min-width: 800px) {
    .k__footer__items {
      display: flex;
      justify-content: space-between; } }

.k__form__group.--loading .k__form__group__inner {
  pointer-events: none;
  opacity: 0.25;
  transform: scale(0.98); }

.k__form__group__inner {
  transition: opacity 0.125s cubic-bezier(0, 0.85, 0.18, 0.99), transform 0.125s cubic-bezier(0, 0.85, 0.18, 0.99); }

.k__forms__content {
  margin-bottom: 3rem; }

.k__form__section {
  background: #f8fafc;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem; }

.k__form {
  text-align: left;
  margin: 0 0 1rem 0; }
  .k__form .--hidden {
    display: none; }
  .k__form .vue-tel-input {
    padding: 0.25rem 0.5rem;
    border: solid #d2d6dc 1px;
    border-radius: 0.5rem;
    width: 100%;
    transition: box-shadow 0.25s cubic-bezier(0, 0.85, 0.18, 0.99);
    background-color: #fff; }
  .k__form .multiselect__tags {
    border-color: #d2d6dc; }
  .k__form.k__grid__item {
    margin: 0; }
  .k__form .k__alert {
    margin-top: 1rem;
    padding: 0.25rem 0.75rem 0.3rem 0.75rem;
    display: inline-block; }

.k__form__label {
  user-select: none;
  display: block;
  font-weight: 600;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.3236rem; }

.k__form__input {
  font-size: 16px;
  padding: 0.75rem 1rem;
  border: solid #d2d6dc 1px;
  border-radius: 0.5rem;
  width: 100%;
  transition: box-shadow 0.25s cubic-bezier(0, 0.85, 0.18, 0.99);
  resize: none; }
  .k__form__input:hover {
    outline: none; }
  .k__form__input:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem #4e81ab; }
  .k__form__input .--required {
    display: inline-block;
    color: #e66060;
    margin-left: 0.15rem; }

.k__form__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -0.75rem; }

.k__form__action {
  margin: 0.75rem; }

.k__checkbox {
  display: flex;
  align-items: center; }

.k__form__radio {
  user-select: none;
  display: inline-flex;
  align-items: center; }
  .k__form__radio .k__form__label {
    margin: 0;
    padding: 0 1rem 0 0.5rem; }

.k__form__item {
  margin-bottom: 1.618rem; }

.k__header {
  position: sticky;
  top: 0;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  color: #495b61;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  padding: 1rem;
  font-weight: 500;
  z-index: 99999; }
  .k__header .k__tel {
    font-size: .85rem; }

.k__tools {
  margin: 0 0 0 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1; }
  .k__tools.--icon {
    width: 3rem;
    height: 3rem; }
  @media (min-width: 800px) {
    .k__tools {
      flex: 0; } }

.k__tool {
  position: relative; }
  .k__tool .k__select__content {
    top: 3.5rem;
    right: 0; }
  .k__tool.k__trigger__select {
    padding-right: 1rem; }
    .k__tool.k__trigger__select::after {
      content: "";
      display: block;
      position: absolute;
      right: 1rem;
      top: 50%;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid currentColor;
      opacity: 0.5;
      transition: transform 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
    .k__tool.k__trigger__select.--active::after {
      transform: rotateZ(180deg); }
  .k__tool:first-child .k__tool__link::after {
    content: none; }

.k__tool__link {
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem; }
  .k__tool__link .k__icon {
    width: 1.5rem;
    height: 1.5rem; }
  .k__tool__link::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    height: 100%;
    width: 1px;
    background: #ebeced; }
  .k__tool__link:hover, .k__tool__link:focus {
    outline: none;
    color: #22e39e; }
  .k__tool__link.--label .k__tool__link__label {
    display: block; }

.k__tool__link__label {
  display: none; }

.k__tel {
  position: relative;
  display: inline-flex;
  border: solid currentColor 2px;
  border-radius: 2em;
  align-items: center;
  color: #22e39e;
  text-decoration: none; }
  .k__tel::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 1em;
    border-top: 0.25em solid transparent;
    border-bottom: 0.25em solid transparent;
    border-left: 0.5em solid #fff;
    transform: translateY(-50%); }

.k__tel__label {
  border-radius: 2em;
  background: currentColor;
  text-transform: uppercase; }
  .k__tel__label > span {
    display: inline-block;
    color: #fff;
    padding: 0.65em 1.5em 0.65em 2.5em;
    font-size: 0.85em; }

.k__tel__num {
  padding: 0 1.5em 0 1em;
  font-weight: bold; }

.k__hour {
  position: relative;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.75rem; }
  .k__hour .--close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%); }
  .k__hour .k__hour__item__time {
    font-weight: bold;
    margin: 0.5rem 0.75rem; }
    .k__hour .k__hour__item__time .field-input {
      font-weight: bold; }
    .k__hour .k__hour__item__time input {
      font-size: 16px !important; }
  .k__hour .k__hour__item .date-time-picker .field-input {
    border: none;
    border-radius: 0;
    border-bottom: dashed #ddd 1px; }
  @media (min-width: 800px) {
    .k__hour {
      display: flex; } }

.k__hour__item {
  margin: 0.5rem; }
  .k__hour__item.--display {
    font-weight: bold; }
    .k__hour__item.--display::first-letter {
      text-transform: uppercase; }
  .k__hour__item.--date {
    margin: 0; }
  .k__hour__item.--display, .k__hour__item.--checkbox {
    flex: 0 0 6rem; }
  .k__hour__item.--range {
    flex: 0 0 50%; }

.k__hour__item__content {
  align-items: center;
  position: relative;
  height: 100%; }
  .k__hour__item__content label {
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    padding-left: 0.5rem; }
  @media (min-width: 800px) {
    .k__hour__item__content {
      display: flex; } }

.k__hour__item__times {
  display: flex;
  margin: 0; }
  @media (min-width: 800px) {
    .k__hour__item__times {
      margin: -0.5rem 0.5rem -0.5rem 0.5rem; } }

.k__icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem; }
  .k__icon > svg {
    height: 100%;
    width: 100%;
    fill: currentColor; }

.k__icon__alert {
  font-size: 0.65em;
  font-style: normal;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  background: #4e81ab;
  color: #fff;
  height: 1.85em;
  min-width: 1.85em;
  border-radius: 0.75rem;
  font-weight: bold;
  transform: translate(50%, -50%);
  padding: 0 0.35rem; }

.k__img {
  height: auto;
  max-width: 100%; }

.k__kanban__column {
  position: relative;
  background: #f4f7f8;
  border-radius: 0.5rem;
  padding: 0.809rem;
  margin: 0.809rem 0; }

.k__kanban__item {
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); }

.k__kanban__content {
  background: #fff;
  max-width: 100rem;
  padding: 1.618rem;
  margin: 1rem auto;
  border-radius: 0.65rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  overflow-x: auto; }
  @media (min-width: 800px) {
    .k__kanban__content {
      margin: 1.618rem 0; } }

.k__kanban__title {
  margin-top: 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 0.02em; }

.k__kanban__item__content {
  display: flex;
  align-items: center;
  width: 100%; }
  .k__kanban__item__content .k__social__bubble {
    color: #fff;
    font-size: 8px;
    margin: 2.5px; }

.k__kanban__item__thumbnail {
  width: 5rem;
  margin-right: 0.75rem;
  border-radius: 6px; }

.k__kanban__item__message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 0.9rem;
  margin-left: 0.25rem; }

.k__kanban__item__message__date {
  font-size: 0.8rem;
  display: block;
  font-weight: bold; }

.k__kanban__item__message__content {
  opacity: 0.75; }

.k__kanban__item {
  position: relative;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  margin-bottom: 0.809rem;
  position: relative;
  min-height: 3rem;
  display: flex; }
  .k__kanban__item.--ghost {
    filter: grayscale(1);
    background: none;
    border: dashed rgba(0, 0, 0, 0.05) 1px;
    box-shadow: none; }
    .k__kanban__item.--ghost .k__kanban__item__content {
      opacity: 0.25; }

.k__kanban__area {
  position: relative;
  height: 100%; }

.k__kanban__columns {
  display: flex;
  margin: -0.809rem;
  min-width: 70rem; }

.k__kanban__column {
  margin: 0.809rem;
  flex: 0 0 calc(33.333333% - 2rem);
  max-width: calc(33.333333% - 2rem); }

.k__kanban__column__content .k__loading {
  top: 4rem; }

@media (min-width: 1025px) {
  .k__kpis__wrapper {
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); } }

.k__kpis {
  margin: 1em -1em 0 -1em;
  user-select: none;
  min-height: 6rem;
  display: flex;
  overflow-x: auto; }
  @media (min-width: 1025px) {
    .k__kpis {
      margin: 0 -1em;
      overflow: visible; } }
  @media (min-width: 1200px) {
    .k__kpis {
      flex-flow: row nowrap; } }

.k__kpi {
  text-align: center;
  position: relative;
  padding: 1.25em 1em 1.5em 1em;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  flex: 0 0 calc(50% - 1rem);
  border-radius: 0.5rem;
  margin: 0.5rem; }
  .k__kpi .k__kpi__value {
    color: currentColor; }
  .k__kpi::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: bottom center;
    background: #22e39e;
    transition: transform 0.1s;
    transform: scaleY(0);
    border-radius: 0.5rem; }
  .k__kpi.--active {
    color: #fff; }
    .k__kpi.--active::before {
      transform: scaleY(1); }
    .k__kpi.--active .k__kpi__value {
      color: #fff; }
  .k__kpi.--click-enabled {
    cursor: pointer; }
  .k__kpi .--no-val {
    display: inline-block;
    font-weight: 200;
    opacity: 0.75;
    width: 1em; }
  @media (min-width: 800px) {
    .k__kpi {
      flex: 0 0 calc(33.333333% - 1rem); } }
  @media (min-width: 1025px) {
    .k__kpi {
      color: currentColor;
      text-align: left;
      background: none;
      box-shadow: none;
      margin: 0;
      flex: 1; }
      .k__kpi.--active {
        color: inherit; }
        .k__kpi.--active .k__kpi__value {
          color: inherit; }
      .k__kpi::before {
        border-radius: 0;
        height: 4px; } }
  @media (min-width: 1200px) {
    .k__kpi {
      flex: 1; } }

.k__kpi__content {
  position: relative; }

.k__kpi__title {
  display: block;
  font-size: 0.75em;
  opacity: 0.5;
  line-height: 1.2; }

.k__kpi__value {
  display: block;
  font-size: 1.75em;
  line-height: 1.2;
  font-weight: 600;
  color: #3f444a; }
  .k__kpi__value em {
    display: inline-block;
    font-style: normal;
    opacity: 0.5;
    font-size: 0.5em;
    margin-left: 0.25em; }

.k__kpi__diff__string {
  display: block;
  font-size: 0.75rem;
  opacity: 0.5; }

.k__kpi__diff {
  position: relative;
  display: inline-block;
  background: #fff;
  font-size: 0.75em;
  padding: 0.5em 1em 0.5em 2em;
  margin-top: 0.45em;
  border-radius: 2rem;
  color: white; }
  .k__kpi__diff svg {
    fill: #fff;
    position: absolute;
    top: 50%;
    left: 0.6rem;
    width: 0.5rem;
    transform: translateY(-50%) rotateZ(180deg); }
    .k__kpi__diff svg.--up {
      transform: translateY(-50%); }
  .k__kpi__diff.--bad {
    color: #9a1c1c;
    background: #fde8e7; }
  .k__kpi__diff.--good {
    color: #04543f;
    background: #def7ec; }

.k__kpis__error {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .k__kpis__error .k__label {
    animation: slideFromBottomSoft 0.2s; }

.k__labels {
  margin: -0.5rem; }
  .k__labels > .k__label {
    margin: 0.5rem; }

.k__label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 0.85714rem;
  color: #636363;
  font-weight: 600;
  background-color: #efefef;
  padding: 0.6rem 1rem;
  border-radius: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1; }
  .k__label.--actif {
    cursor: pointer; }
  .k__label.--status {
    position: relative;
    padding-left: 2rem; }
    .k__label.--status::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0.75rem;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #636363;
      transform: translateY(-50%); }
  .k__label.--release {
    color: #04543f;
    background-color: #e9f8ea; }
    .k__label.--release::before {
      background-color: #72d478; }
    .k__label.--release.--active {
      box-shadow: 0 0 0 2px #72d478; }
  .k__label.--draft, .k__label.--grey {
    color: #46505c;
    background-color: #e0e6ee;
    opacity: 0.65; }
    .k__label.--draft::before, .k__label.--grey::before {
      background-color: #46505c; }
    .k__label.--draft.--active, .k__label.--grey.--active {
      box-shadow: 0 0 0 2px #46505c; }
  .k__label.--scheduled, .k__label.--blue {
    color: #318195;
    background-color: #d9f8ff; }
    .k__label.--scheduled::before, .k__label.--blue::before {
      background-color: #318195; }
    .k__label.--scheduled.--active, .k__label.--blue.--active {
      box-shadow: 0 0 0 2px #318195; }
  .k__label.--published, .k__label.--green {
    background: #e2f5e3;
    color: #2d7732; }
    .k__label.--published::before, .k__label.--green::before {
      background-color: #2d7732; }
    .k__label.--published.--active, .k__label.--green.--active {
      box-shadow: 0 0 0 2px #2d7732; }
  .k__label.--warning, .k__label.--orange {
    color: #705e43;
    background-color: #fff5e7; }
    .k__label.--warning::before, .k__label.--orange::before {
      background-color: #ffc368; }
    .k__label.--warning.--active, .k__label.--orange.--active {
      box-shadow: 0 0 0 2px #ffc368; }
  .k__label.--danger, .k__label.--red {
    color: #9a1c1c;
    background-color: #fde8e7; }
    .k__label.--danger::before, .k__label.--red::before {
      background-color: #e66060; }
    .k__label.--danger.--active, .k__label.--red.--active {
      box-shadow: 0 0 0 2px #e66060; }
  .k__label.--mini {
    font-size: 0.7rem;
    padding: 0.3rem 0.6rem; }
  .k__label .k__icon {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.75rem;
    margin-left: -0.25rem; }

.k__container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 15rem; }
  @media (min-width: 800px) {
    .k__container {
      padding-bottom: 9rem; } }

.k__main {
  padding: 2rem 0; }

.k__content {
  position: relative;
  max-width: 100rem;
  margin: 0 auto;
  padding-left: 0.809rem;
  padding-right: 0.809rem; }
  .k__content.--mini {
    background: #fff;
    max-width: 64.72rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }
    @media (min-width: 800px) {
      .k__content.--mini {
        margin-top: 3.236rem;
        margin-bottom: 3.236rem;
        padding: 1.618rem 3.236rem; } }
  .k__content > .k__alert {
    margin: 1.618rem 0; }
  @media (min-width: 800px) {
    .k__content {
      padding-left: 1.618rem;
      padding-right: 1.618rem; } }

.k__logo {
  display: block;
  line-height: 0;
  max-width: 8rem; }

.k__grid.--maxi {
  display: block; }
  .k__grid.--maxi .k__grid__item {
    max-width: 100%; }
  @media (min-width: 640px) {
    .k__grid.--maxi {
      display: flex; }
      .k__grid.--maxi.--g-2 .k__grid__item, .k__grid.--maxi.--g-3 .k__grid__item, .k__grid.--maxi.--g-4 .k__grid__item {
        flex: 0 0 50%;
        max-width: 50%; } }
  @media (min-width: 800px) {
    .k__grid.--maxi.--g-3 .k__grid__item, .k__grid.--maxi.--g-4 .k__grid__item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 1025px) {
    .k__grid.--maxi.--g-4 .k__grid__item {
      flex: 0 0 25%;
      max-width: 25%; } }

@media (min-width: 360px) {
  .k__grid {
    display: flex;
    flex-flow: row wrap;
    margin-top: 1.618rem;
    margin-right: -0.809rem;
    margin-bottom: 1.618rem;
    margin-left: -0.809rem; }
    .k__grid.--g-2 .k__grid__item, .k__grid.--g-3 .k__grid__item, .k__grid.--g-4 .k__grid__item {
      flex: 0 0 50%; } }

@media (min-width: 480px) {
  .k__grid.--g-3 .k__grid__item, .k__grid.--g-4 .k__grid__item {
    flex: 0 0 33.33333%; } }

@media (min-width: 640px) {
  .k__grid.--g-4 .k__grid__item {
    flex: 0 0 25%; } }

.k__grid__item {
  padding: 0.809rem; }

.k__content__title {
  display: flex;
  margin-bottom: 0.5rem; }
  .k__content__title .k__bton {
    height: auto; }
    .k__content__title .k__bton .k__icon {
      height: 100%; }

.k__g {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5vw; }
  @media (min-width: 640px) {
    .k__g {
      grid-template-columns: repeat(2, 1fr); } }

.k__list {
  background: #fff;
  max-width: 100rem;
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }
  .k__list .k__social__bubble {
    color: #fff; }

.k__list__sorter {
  user-select: none;
  position: relative;
  font-size: 1.15em;
  margin-bottom: 1em; }

.k__list__content {
  position: relative;
  min-height: 10rem;
  background: #fff; }

.k__list__sorter__current {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.5em;
  font-weight: bold; }

.k__list__sorter__choices {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 0.5em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  padding: 0.75em 1.75em;
  animation: slideFromBottomSoft 0.15s; }

.k__list__sorter__choice {
  font-size: 1em;
  font-weight: bold;
  margin: 0.35em 0; }

.k__list__sorter__order {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  position: relative;
  transform: rotateZ(90deg);
  transition: transform 0.15s; }
  .k__list__sorter__order svg {
    height: 0.75em;
    width: 0.75em; }
  .k__list__sorter__order.--desc {
    transform: rotateZ(-90deg); }

.k__list__row {
  user-select: none;
  background: #fff;
  border-bottom: solid #f4f4f4 1px;
  transition: box-shadow 0.1s;
  animation: slideFromBottomSoft 0.15s; }
  .k__list__row:hover {
    position: relative;
    box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.1); }

.k__social__bubble {
  font-size: 0.9em;
  display: flex;
  height: 2em;
  width: 2em;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }
  .k__social__bubble .k__icon {
    height: 1em;
    width: 1em; }

.k__list__col__img {
  max-width: 100%;
  height: auto; }

.k__network__selector {
  user-select: none;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0.5rem 1rem; }
  .k__network__selector input[type="checkbox"] {
    display: none; }
  .k__network__selector::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    height: 1.25rem;
    width: 1.25rem;
    border: solid #d5dcde 1px;
    border-radius: 4px;
    transform: translateY(-50%); }
  .k__network__selector::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.625rem;
    height: 0.75rem;
    width: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0351 1L5.46535 7.09242C5.25969 7.36561 4.94586 7.53645 4.60478 7.56087C4.2637 7.5853 3.92873 7.46093 3.68623 7.21983L1.32666 4.86026' stroke='%23636363' stroke-width='1.57305' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    transform: translate(50%, -40%);
    opacity: 0; }
  .k__network__selector.--selected {
    background: white; }
    .k__network__selector.--selected::after {
      opacity: 1; }
  .k__network__selector.--disabled {
    cursor: default; }
    .k__network__selector.--disabled::before, .k__network__selector.--disabled::after {
      content: none; }
    .k__network__selector.--disabled.--error::after {
      content: "!";
      display: flex;
      color: #fff;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.75);
      align-items: center;
      justify-content: center;
      font-size: 0.65rem;
      font-weight: bold;
      height: 1rem;
      width: 1rem;
      transform: translate(120%, -130%); }
  .k__network__selector:hover, .k__network__selector:focus, .k__network__selector:active {
    background: white; }

.k__network__details {
  user-select: none;
  width: calc(100% - 50px); }

.k__network__title {
  display: block;
  line-height: 1.2;
  padding-right: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.15rem; }

.k__network__description {
  display: block;
  padding-right: 2rem;
  font-weight: normal;
  opacity: 0.5;
  font-size: 0.89rem;
  line-height: 1.2; }

.k__network__avatar {
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 1rem;
  border-radius: 50%;
  background: #e4e4e4; }
  .k__network__avatar .k__social__bubble {
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.65em; }
  .k__network__avatar .k__network__thumbnail {
    border-radius: 50%;
    overflow: hidden;
    height: 50px;
    width: 50px; }
    .k__network__avatar .k__network__thumbnail > img {
      height: 50px;
      width: 50px;
      width: 100%;
      height: auto; }
  .k__network__avatar.--full .k__social__bubble {
    height: 100%;
    width: 100%; }

.k__list__pagination {
  position: relative;
  margin: 2rem 0; }
  .k__list__pagination .k__select {
    display: inline-block; }
  @media (min-width: 800px) {
    .k__list__pagination {
      display: flex;
      justify-content: center;
      align-items: center; } }

.k__list__pagination__p {
  display: inline-block;
  text-align: center;
  font-weight: bold;
  margin: 0 1rem; }

.k__list__pagination__select {
  width: 100%; }
  @media (min-width: 800px) {
    .k__list__pagination__select {
      width: auto;
      position: absolute;
      left: 0; } }

.k__app__list__filters {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  background: white;
  border: solid #eef3f6 1px; }
  .k__app__list__filters .k__select {
    margin: 0;
    border-radius: 0;
    border: none; }
  .k__app__list__filters .k__bton {
    margin-right: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
    box-shadow: none;
    opacity: 0.75; }

.k__app__list__filter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.4rem;
  position: relative;
  flex: 1;
  border-left: solid #eef3f6 1px; }
  .k__app__list__filter > .k__icon {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    left: 1rem;
    top: 50%;
    opacity: 0.5;
    pointer-events: none;
    transform: translateY(-50%); }
  .k__app__list__filter.--dimension {
    display: none; }
    @media (min-width: 800px) {
      .k__app__list__filter.--dimension {
        display: flex; } }
  .k__app__list__filter:first-child {
    border: none; }
  .k__app__list__filter.--global {
    display: flex;
    align-items: center;
    flex: 0;
    padding: 0 1vw; }
    .k__app__list__filter.--global .k__app__list__filter__global__actions {
      margin-left: 1vw; }
    .k__app__list__filter.--global .k__bton {
      margin: 0; }
  .k__app__list__filter.--search {
    align-self: stretch; }

.k__app__list__filter__input {
  position: relative;
  height: 100%;
  width: 100%;
  border: none;
  background: none;
  padding-left: 3rem; }

.k__app__list__sorters {
  padding: 1vw;
  background: #fff;
  border-bottom: solid #eef3f6 1px;
  display: flex; }
  .k__app__list__sorters.--global-select {
    padding-left: calc(2vw + 1rem); }

.k__app__list__sorter {
  user-select: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  opacity: 0.5;
  margin-right: 1rem; }
  .k__app__list__sorter > .k__icon {
    transform: rotateZ(90deg);
    height: 0.6rem;
    width: 0.6rem;
    opacity: 0; }
  .k__app__list__sorter:hover > .k__icon, .k__app__list__sorter.--active > .k__icon {
    opacity: 1; }
  .k__app__list__sorter.--up > .k__icon {
    transform: rotateZ(-90deg); }

.k__app__list {
  opacity: 1;
  background-color: #fff; }
  .k__app__list .k__table {
    font-size: 1rem;
    margin: 0; }
  .k__app__list td {
    padding: 1.5rem;
    padding: 1.5vw; }
  .k__app__list.--edit {
    position: relative; }
    .k__app__list.--edit .k__app__list__filters {
      pointer-events: none; }
      .k__app__list.--edit .k__app__list__filters .k__app__list__filter,
      .k__app__list.--edit .k__app__list__filters .k__bton {
        opacity: 0.25; }
    .k__app__list.--edit .k__app__list__edit {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    @media (min-width: 800px) {
      .k__app__list.--edit {
        display: flex; }
        .k__app__list.--edit .k__app__list__inner {
          flex: 0 0 50%; }
        .k__app__list.--edit .k__app__list__edit {
          position: static;
          flex: 0 0 50%; } }

.--panel-portal .k__app__list__edit {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.1), 0px 1px 4rem rgba(0, 0, 0, 0.15);
  overflow: hidden; }

@media (min-width: 800px) {
  .--panel-portal .k__app__list__edit {
    width: 50%; } }

.k__app__list__edit__actions {
  text-align: left;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0.1rem;
  right: 1rem;
  z-index: 1; }
  .k__app__list__edit__actions .k__icon {
    opacity: 0.75;
    height: 1rem;
    width: 1rem; }

.k__app__list__inner {
  flex: 1; }

.k__app__list__edit__header {
  padding: 1.5vw; }

.k__app__list__edit__content {
  padding: 1.5vw;
  background: #fff;
  overflow-y: auto; }
  .k__app__list__edit__content .k__tabs__header {
    margin: -1.5vw -1.5vw 1.5vw -1.5vw; }

.k__app__list__edit__footer {
  padding: 1.5vw; }

.--listline .k__app__list__line,
.--minilistline .k__app__list__line {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.75vw 1.5vw;
  border-bottom: solid #eef3f6 1px;
  border-left: solid #eef3f6 1px;
  border-right: solid #eef3f6 1px; }

.--listline .--global,
.--minilistline .--global {
  margin-left: -1.5vw; }

.--listline .k__app__list__line__inner,
.--minilistline .k__app__list__line__inner {
  display: flex;
  align-items: center;
  flex: 1; }

.--listline .k__app__list__col,
.--minilistline .k__app__list__col {
  order: 1;
  margin: 0 0.5rem; }
  .--listline .k__app__list__col:first-child,
  .--minilistline .k__app__list__col:first-child {
    margin-left: 0; }
  .--listline .k__app__list__col:last-child,
  .--minilistline .k__app__list__col:last-child {
    margin-right: 0; }
  .--listline .k__app__list__col.--right,
  .--minilistline .k__app__list__col.--right {
    flex: 1;
    order: 2;
    text-align: right; }

.--listline .--price,
.--minilistline .--price {
  font-weight: bold; }

.--listline .--description,
.--minilistline .--description {
  opacity: 0.75; }

@media (min-width: 800px) {
  .--card .k__app__list__edit {
    margin-left: 1.5vw; } }

.--cardline,
.--minicardline {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5vw;
  margin: 1.5vw 0; }
  .--cardline > *,
  .--minicardline > * {
    flex: 0 0 20%; }
  .--cardline .k__app__list__line,
  .--minicardline .k__app__list__line {
    border-radius: 0.5rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }
  .--cardline .k__app__list__col,
  .--minicardline .k__app__list__col {
    margin: 0.75rem; }
  .--cardline .--thumbnail,
  .--minicardline .--thumbnail {
    line-height: 0;
    height: 14rem;
    height: 14vw;
    margin: -1vw -1vw 1vw -1vw; }
    .--cardline .--thumbnail > img,
    .--minicardline .--thumbnail > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .--cardline .--thumbnail .k__app__list__line__actions,
    .--minicardline .--thumbnail .k__app__list__line__actions {
      margin-top: 1.5vw; }
  .--cardline .--pretitle,
  .--minicardline .--pretitle {
    font-size: 0.8rem;
    opacity: 0.75; }
  .--cardline .--title,
  .--minicardline .--title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
  .--cardline .--description,
  .--minicardline .--description {
    margin-bottom: 0.75rem; }
  .--cardline .--price,
  .--minicardline .--price {
    font-weight: bold; }
  .--cardline .k__app__list__line,
  .--minicardline .k__app__list__line {
    text-align: center; }
  @media (min-width: 800px) {
    .--cardline,
    .--minicardline {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 1025px) {
    .--cardline,
    .--minicardline {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 1200px) {
    .--cardline,
    .--minicardline {
      grid-template-columns: repeat(5, 1fr); } }

.--medialine {
  margin: 1rem 0; }
  .--medialine > * {
    flex: 0 0 20%; }
  .--medialine .k__app__list__line {
    border-radius: 0.5rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
    margin: 1rem 0; }
  .--medialine .k__app__list__col {
    margin: 0.75rem; }
  .--medialine .--thumbnail {
    line-height: 0;
    height: 14rem;
    height: 14vw;
    margin: -1vw -1vw 1vw -1vw; }
    .--medialine .--thumbnail > img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .--medialine .--thumbnail .k__app__list__line__actions {
      margin-top: 1.5vw; }
  .--medialine .--pretitle {
    font-size: 0.8rem;
    opacity: 0.75; }
  .--medialine .--title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
  .--medialine .--description {
    margin-bottom: 0.75rem; }
  .--medialine .--price {
    font-weight: bold; }
  .--medialine .k__app__list__line {
    text-align: center; }
  @media (min-width: 640px) {
    .--medialine {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5vw; }
      .--medialine .k__app__list__line {
        margin: 0; } }
  @media (min-width: 800px) {
    .--medialine {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 1025px) {
    .--medialine {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 1200px) {
    .--medialine {
      grid-template-columns: repeat(5, 1fr); } }

.--medialine .k__app__list__line,
.--minimedialine .k__app__list__line {
  position: relative;
  box-shadow: none;
  overflow: hidden;
  padding: 0;
  border: solid 1px #eee; }

.--medialine .k__app__list__links,
.--minimedialine .k__app__list__links {
  position: absolute;
  top: 1rem;
  right: 1rem; }

.--medialine .k__app__list__link,
.--minimedialine .k__app__list__link {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  background: #fff;
  padding: 0.75rem;
  border-radius: 50%; }
  .--medialine .k__app__list__link svg,
  .--minimedialine .k__app__list__link svg {
    opacity: 0.75; }

.--medialine .k__app__list__icon,
.--minimedialine .k__app__list__icon {
  position: absolute;
  left: 1rem; }

.--medialine .k__app__list__line__inner,
.--minimedialine .k__app__list__line__inner {
  min-height: 4.4rem;
  padding: 1rem; }

.--medialine .k__app__list__line__content,
.--minimedialine .k__app__list__line__content {
  text-align: left;
  padding-left: 2.75rem;
  line-height: 1.35; }

.--medialine .--title,
.--minimedialine .--title {
  font-size: 0.9rem;
  margin: 0 0 0 0; }
  .--medialine .--title .k__app__list__col,
  .--minimedialine .--title .k__app__list__col {
    margin: 0; }
    .--medialine .--title .k__app__list__col span,
    .--minimedialine .--title .k__app__list__col span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.--medialine .--infos,
.--minimedialine .--infos {
  font-size: 0.8rem;
  opacity: 0.75;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.--medialine .--thumbnail,
.--minimedialine .--thumbnail {
  margin: 0;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpattern id='pattern-checkers' x='0' y='0' width='10' height='10' patternUnits='userSpaceOnUse'%3E%3Crect class='checker' x='0' width='5' height='5' y='0' fill='%23eeeeee'%3E%3C/rect%3E%3Crect class='checker' x='5' width='5' height='5' y='5' fill='%23eeeeee'%3E%3C/rect%3E%3C/pattern%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23pattern-checkers)'%3E%3C/rect%3E%3C/svg%3E");
  height: 10rem; }
  .--medialine .--thumbnail .k__app__list__col,
  .--minimedialine .--thumbnail .k__app__list__col {
    margin: 0;
    display: block;
    height: 10rem; }
    .--medialine .--thumbnail .k__app__list__col span,
    .--minimedialine .--thumbnail .k__app__list__col span {
      height: 10rem;
      display: block;
      line-height: 0; }
  .--medialine .--thumbnail img,
  .--minimedialine .--thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  @media (min-width: 800px) {
    .--medialine .--thumbnail .--thumbnail,
    .--minimedialine .--thumbnail .--thumbnail {
      height: 10vw; }
      .--medialine .--thumbnail .--thumbnail .k__app__list__col,
      .--minimedialine .--thumbnail .--thumbnail .k__app__list__col {
        height: 10vw; }
        .--medialine .--thumbnail .--thumbnail .k__app__list__col span,
        .--minimedialine .--thumbnail .--thumbnail .k__app__list__col span {
          height: 10vw; } }

.--minimedialine {
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;
  grid-gap: 1.5vw;
  margin: 1.5vw 0; }
  .--minimedialine .k__app__list__line {
    border-radius: 0.5rem; }

.--minicardline {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-gap: 1.5vw;
  margin: 1.5vw 0; }

.k__app__list__line {
  background: #fff;
  padding: 1vw; }
  .k__app__list__line td img {
    max-width: 6rem; }

.k__app__list__location.--title {
  font-weight: bold; }

.k__app__list__col.--title {
  font-weight: bold; }

.k__app__list__col .--thumbnail {
  line-height: 0;
  width: 4rem; }
  .k__app__list__col .--thumbnail > img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.k__app__list__col:empty {
  display: none; }

.k__app__list__edit {
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  border-top: solid #f6f8f9 2px;
  border-right: solid #f6f8f9 2px;
  border-bottom: solid #f6f8f9 2px; }
  .k__app__list__edit .k__tabs__nav__items {
    margin: 0 -0.75rem !important;
    padding: 0 !important; }
  .k__app__list__edit .k__tabs__nav__item {
    padding: 0.9vw 0 1vw 0 !important; }
  .k__app__list__edit .multiselect__tag {
    display: inline-block !important; }

.k__search__highlight {
  background: #fffd54; }

.k__app__list__line__actions {
  text-align: right; }

.k__app__list__line__action.k__bton {
  display: inline-flex;
  height: 2.5rem;
  width: 2.5rem;
  box-shadow: none; }

.k__app__list__line__action .k__icon {
  height: 1rem;
  width: 1rem;
  opacity: 0.75; }

.k__app__list__filters__tablet {
  background: #fff;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  font-weight: 500;
  text-align: center; }
  .k__app__list__filters__tablet .k__app__list__filter__label {
    cursor: pointer;
    width: 100%; }
  .k__app__list__filters__tablet .k__app__list__filer__options {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    left: 0;
    right: 0; }
  .k__app__list__filters__tablet .k__app__list__filter__option {
    background: #fff;
    border-bottom: solid #ebf0f2 1px;
    padding: 1rem 0; }

.k__app__list__filters__tablet .k__app__list__filter {
  display: flex; }

@media (min-width: 800px) {
  .k__app__list__filters__tablet {
    display: none; } }

.k__list__header {
  margin-bottom: 1rem; }
  .k__list__header .k__select {
    margin: 0 0 0.75rem 0; }
  .k__list__header .k__bton {
    justify-content: center;
    width: 100%; }

.--mobile .k__list__row {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  position: relative;
  padding-left: 7rem;
  min-height: 8rem;
  padding-top: 0.25rem; }

.--mobile .k__list__col.--media {
  position: absolute;
  top: 0.5rem;
  left: 0;
  bottom: 0.5rem;
  background: #eee;
  border-radius: 4 px;
  overflow: hidden; }
  .--mobile .k__list__col.--media img,
  .--mobile .k__list__col.--media video {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.--mobile .k__list__col.--socials {
  margin: 0.5rem 0 0.25rem 0; }
  .--mobile .k__list__col.--socials .k__social__bubble {
    position: relative; }

.--mobile .k__list__col.--updated {
  margin-bottom: 0.25rem; }

.--mobile .k__list__col.--status {
  margin: 0.25rem 0; }

.--mobile .k__list__col.--message {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0.5rem; }

.--desktop .k__list {
  margin: 1.618rem 0;
  padding: 1.618rem; }

.--desktop .k__list__header {
  display: flex;
  align-items: center;
  background: #fff;
  margin: -0.75rem -0.75rem 0.75rem -0.75rem; }
  .--desktop .k__list__header .k__select {
    margin: 0.75rem; }
  .--desktop .k__list__header .k__bton {
    width: auto; }

.--desktop .k__list__row {
  display: flex;
  align-items: center;
  padding: 0.5em; }

.--desktop .k__list__col {
  margin: 0 0.5em; }
  .--desktop .k__list__col.k__label {
    padding-left: 1rem;
    font-size: 0.8rem; }
    .--desktop .k__list__col.k__label::before {
      content: none; }
  .--desktop .k__list__col.--socialtype {
    margin: 0; }
  .--desktop .k__list__col.--socialname {
    font-weight: bold; }
  .--desktop .k__list__col.--message {
    font-size: 0.95em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    opacity: 0.75;
    line-height: 1.25; }
    .--desktop .k__list__col.--message > b {
      display: block; }
  .--desktop .k__list__col.--updated {
    font-size: 0.95em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.75;
    width: 14rem; }
  .--desktop .k__list__col.--media {
    line-height: 0;
    background: #f4f4f4;
    border-radius: 6px;
    overflow: hidden;
    min-height: 2rem; }

.k__app__list__icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  height: 4rem;
  width: 4rem; }
  .k__app__list__icon__wrapper .k__app__list__icon {
    margin: 0; }

.k__list__detail__resume {
  position: relative; }
  .k__list__detail__resume .k__app__list__icon__wrapper {
    position: absolute;
    top: 1rem;
    left: 1rem; }
  @media (min-width: 800px) {
    .k__list__detail__resume {
      display: flex; } }

.k__list__detail__thumbnail {
  flex: 0 0 40%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpattern id='pattern-checkers' x='0' y='0' width='10' height='10' patternUnits='userSpaceOnUse'%3E%3Crect class='checker' x='0' width='5' height='5' y='0' fill='%23eeeeee'%3E%3C/rect%3E%3Crect class='checker' x='5' width='5' height='5' y='5' fill='%23eeeeee'%3E%3C/rect%3E%3C/pattern%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23pattern-checkers)'%3E%3C/rect%3E%3C/svg%3E");
  height: 12rem; }
  .k__list__detail__thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  @media (min-width: 800px) {
    .k__list__detail__thumbnail {
      height: 18vw;
      margin-right: 1vw; } }

.k__list__detail__infos {
  padding: 1rem; }
  @media (min-width: 800px) {
    .k__list__detail__infos {
      padding: 0;
      flex: 1;
      width: calc(60% - 1vw); } }

.k__list__detail__title {
  position: relative;
  padding-right: 3.5rem;
  margin-top: 0.5rem; }
  .k__list__detail__title .k__app__list__icon {
    position: absolute;
    left: 0; }
  .k__list__detail__title h3 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    color: #4a4a4a; }

.k__list__defs {
  font-size: 0.9rem;
  margin-top: 0.75rem; }

.k__list__def strong {
  display: inline-block;
  margin-right: 0.5rem; }

.k__list__def span {
  opacity: 0.75; }

.k__list__tags {
  display: flex;
  flex-flow: row wrap;
  margin: 0.75rem -0.25rem; }

.k__list__tag {
  margin: 0.25rem;
  padding: 0.3rem 0.75rem; }
  .k__list__tag.--rest {
    cursor: pointer;
    color: #fff;
    background: #4a4a4a; }

.k__list__detail__edit {
  position: relative;
  min-height: 4rem;
  margin: 1rem 0 4rem 0;
  padding: 1rem 0; }

.k__list__detail__actions {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  margin: 0 -0.25rem; }

.k__list__detail__action {
  position: relative;
  margin: 0 0.25rem;
  min-width: 10rem;
  min-height: 3.025rem; }
  .k__list__detail__action .k__loading {
    transform: translate(0, -50%); }
  .k__list__detail__action .k__loading__svg__path {
    stroke: #fff; }

.k__social__networks.--select-pdv .k__social__networks__pdv__header {
  position: relative; }
  .k__social__networks.--select-pdv .k__social__networks__pdv__header::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    height: 1.25rem;
    width: 1.25rem;
    border: solid #d5dcde 1px;
    border-radius: 4px;
    transform: translateY(-50%); }
  .k__social__networks.--select-pdv .k__social__networks__pdv__header::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.625rem;
    height: 0.75rem;
    width: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0351 1L5.46535 7.09242C5.25969 7.36561 4.94586 7.53645 4.60478 7.56087C4.2637 7.5853 3.92873 7.46093 3.68623 7.21983L1.32666 4.86026' stroke='%23636363' stroke-width='1.57305' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    transform: translate(50%, -40%);
    opacity: 0; }

.k__social__networks.--select-pdv .k__social__networks__pdv.--selected .k__social__networks__pdv__header {
  background: white; }
  .k__social__networks.--select-pdv .k__social__networks__pdv.--selected .k__social__networks__pdv__header::after {
    opacity: 1; }

.k__content .leaflet-popup-content-wrapper {
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }

.k__map__metric {
  text-align: center;
  margin-top: .25rem; }
  .k__map__metric:first-child {
    margin-top: 0; }

.k__map__metric__name {
  display: block;
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 1.2; }

.k__map__metric__value {
  display: block;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
  color: #3f444a; }

.--mobile .k__content {
  padding-left: 1rem;
  padding-right: 1rem; }

.--mobile .k__subnav__wrapper {
  margin-bottom: 1rem; }

body.--modal-full,
html.--modal-full {
  overflow: hidden;
  min-height: 0;
  max-height: 100vh;
  height: 100vh; }

.k__modal__wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 999999; }

.k__modal__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background: linear-gradient(106.92deg, #4e81ab 0%, #4e81ab 100%);
  z-index: 1; }

.k__modal__header .k__img {
  width: 5rem;
  margin-bottom: 1.5rem; }

.k__modal__content {
  text-align: center;
  position: relative;
  background: #fff;
  padding: 3.236rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  z-index: 2;
  width: 34rem;
  max-height: 100%;
  overflow: auto;
  animation: slideFromBottomSoft 0.75s cubic-bezier(0, 0.85, 0.18, 0.99); }

.k__modal__title {
  font-size: 2rem;
  margin: 0;
  line-height: 1.25; }

.k__modal__desc {
  margin: 0; }

.k__modal__desc__link {
  color: inherit; }

.k__app__modal .k__modal__content {
  padding: 1.618rem;
  padding-bottom: 5.618rem; }

.k__app__modal .k__modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.618rem; }
  .k__app__modal .k__modal__header > h3 {
    margin: 0; }

.k__app__modal .k__modal__inner {
  text-align: left;
  min-height: 4rem; }

.k__app__modal .k__modal__footer {
  position: absolute; }

.k__app__modal .k__modal__footer__actions {
  padding-right: 0; }

.k__modal__footer__actions {
  min-height: 4rem; }

.k__modal__message {
  margin-bottom: 1rem; }

.k__trigger__select {
  position: relative;
  overflow: inherit; }
  .k__trigger__select .k__select__content {
    overflow: auto;
    display: none;
    z-index: 10;
    animation: slideFromBottomSoft 0.2s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__trigger__select .k__select__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(73, 91, 97, 0.25);
    display: none;
    animation: fade 0.1s;
    z-index: 9; }
  .k__trigger__select.--active .k__select__overlay,
  .k__trigger__select.--active .k__select__content {
    display: block; }
  .k__trigger__select.--active .k__select__content {
    position: fixed;
    top: 6.25rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem; }
    @media (min-width: 800px) {
      .k__trigger__select.--active .k__select__content {
        position: absolute;
        top: 4rem;
        right: auto;
        bottom: auto;
        left: 1rem;
        overflow: visible; }
        .k__trigger__select.--active .k__select__content.--right {
          right: 1rem;
          left: auto; } }
    .k__trigger__select.--active .k__select__content.--xl .k__dropdown__link {
      font-weight: bold;
      font-size: 1rem; }
    @media (min-width: 800px) {
      .k__trigger__select.--active .k__select__content.--xl {
        top: 50%;
        left: 50%; }
        .k__trigger__select.--active .k__select__content.--xl .k__dropdown {
          transform: translate(-50%, -50%); } }

.k__nav__content {
  padding: 1.618rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }
  .k__nav__content .k__grid__item {
    flex: 0 0 25% !important; }
  .k__nav__content .k__shortcuts {
    margin-top: 0;
    margin-bottom: 0; }
  @media (min-width: 800px) {
    .k__nav__content {
      top: 4rem;
      left: 1rem;
      width: 40rem; } }

.k__nav__content__sep {
  display: block;
  position: relative; }
  .k__nav__content__sep > span {
    display: inline-block;
    position: relative;
    background: #fff;
    padding-right: 0.5rem;
    color: #a5a9ad;
    font-weight: 200;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05em; }
  .k__nav__content__sep::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    background: #ebeced; }

.k__nav__title {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background: #eef3f6;
  border-radius: 2rem;
  color: #495b61;
  margin: 0 1rem;
  font-size: 13px;
  font-size: 0.92857rem;
  z-index: 1; }
  .k__nav__title::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #22e39e;
    transform: scale(0.975);
    opacity: 0;
    z-index: -1;
    border-radius: 2rem;
    transition: transform 0.25s cubic-bezier(0, 0.85, 0.18, 0.99), opacity 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__nav__title .k__icon {
    height: 0.95rem;
    width: 0.95rem;
    margin-right: 0; }
  .k__nav__title svg {
    fill: currentColor; }
  .k__nav__title:hover, .k__nav__title:focus {
    outline: none; }
    .k__nav__title:hover::before, .k__nav__title:focus::before {
      opacity: 0.1;
      transform: scale(1); }
  @media (min-width: 1025px) {
    .k__nav__title {
      padding: 0.75rem 1.25rem; }
      .k__nav__title .k__icon {
        margin-right: 0.75rem; }
      .k__nav__title .k__nav__title__label {
        display: block; } }

.k__nav__title__label {
  display: none; }

.k__nav__content__actions {
  text-align: right;
  padding: 2rem 0 0 0; }

.k__subnav {
  align-self: stretch;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 1; }

.k__subnav__item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%; }
  .k__subnav__item.--right {
    flex: 1;
    justify-content: flex-end;
    margin: 0 -0.75rem; }
    .k__subnav__item.--right .k__bton,
    .k__subnav__item.--right .k__bton--link {
      margin: 0.75rem; }

.k__subnav__link {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0 0.75rem;
  opacity: 0.75; }
  .k__subnav__link::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: -1rem;
    left: 0;
    height: 3px;
    background-color: #22e39e;
    transform-origin: center bottom;
    transition: transform 0.25s cubic-bezier(0, 0.85, 0.18, 0.99);
    transform: scaleY(0); }
  .k__subnav__link.--active {
    color: #22e39e;
    opacity: 1; }
    .k__subnav__link.--active::before {
      transform: scaleY(1); }
  .k__subnav__link:hover, .k__subnav__link:focus, .k__subnav__link:active {
    outline: none;
    opacity: 1;
    color: #22e39e; }

.k__mobile__subnav {
  padding: 1rem 0;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  overflow-x: auto; }
  .k__mobile__subnav .k__subnav__link {
    font-size: 0.9rem;
    white-space: nowrap;
    font-weight: 600; }

.k__subnav__wrapper {
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06);
  margin-bottom: 1.618rem; }

.k__subnav__items {
  display: flex;
  align-items: center; }

.k__subnav__item {
  position: relative; }
  .k__subnav__item.--icon {
    width: 3.25rem;
    background: none;
    border: none;
    opacity: 0.5;
    padding: 1rem;
    font-weight: 600; }
    .k__subnav__item.--icon svg {
      fill: #495b61; }
  .k__subnav__item.--disabled {
    pointer-events: none;
    opacity: 0.25; }
  .k__subnav__item::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: #22e39e;
    transform-origin: center bottom;
    transform: scaleY(0);
    transition: transform 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__subnav__item:hover, .k__subnav__item:active, .k__subnav__item:focus {
    outline: none; }
  .k__subnav__item.--active::after {
    transform: scaleY(1); }

body.--hidden {
  overflow: hidden; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

html {
  background: #f4f7f8;
  color: #495b61;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a {
  background: transparent; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.75em;
  margin: 2.33em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "“" "”" "‘" "’"; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }

input[type='checkbox'], input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

.k__social__preview.--facebook {
  padding: 1em;
  color: #050505; }
  .k__social__preview.--facebook .k__network__title {
    cursor: default;
    margin-top: 4px;
    font-weight: 600; }
  .k__social__preview.--facebook .k__network__description  {
    color: #65676b;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2308; }
  .k__social__preview.--facebook .k__social__preview__header {
    display: flex;
    padding: 12px 16px 0 16px; }
  .k__social__preview.--facebook .k__social__preview__card {
    overflow: hidden;
    color: #050505;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 1rem; }
  .k__social__preview.--facebook .k__social__preview__message {
    font-size: 15px;
    color: #050505;
    font-weight: 400;
    line-height: 1.3333;
    padding: 8px 16px 12px 16px; }
  .k__social__preview.--facebook .k__social__preview__link {
    animation: slideFromBottomSoft 0.15s;
    position: relative;
    background: #f1f2f5;
    padding: 1rem;
    min-height: 4rem; }
  .k__social__preview.--facebook .k__social__preview__link__inner {
    cursor: pointer; }
  .k__social__preview.--facebook .k__social__preview__link__image {
    line-height: 0;
    margin: -1rem -1rem 1rem -1rem;
    text-align: center; }
    .k__social__preview.--facebook .k__social__preview__link__image > img {
      height: auto;
      max-width: 100%; }
  .k__social__preview.--facebook .k__social__preview__link__url {
    color: #65676b;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.0004px;
    white-space: nowrap; }
  .k__social__preview.--facebook .k__social__preview__link__title {
    color: #050505;
    font-size: 16.5px;
    font-weight: 600;
    line-height: 20.0005px;
    margin-bottom: 0.1rem; }
  .k__social__preview.--facebook .k__social__preview__link__description {
    color: #65676b;
    line-height: 1.25; }

.k__social__preview__url {
  cursor: pointer;
  font-size: 15px;
  color: #216fdb;
  font-weight: 400;
  line-height: 1.3333;
  padding: 0 16px 12px 16px; }

.k__social__message__url {
  cursor: pointer;
  color: #216fdb;
  text-decoration: none; }

.k__social__preview__medias {
  line-height: 0; }
  .k__social__preview__medias img {
    width: 100%; }

.k__monitoring__preview__card {
  margin-bottom: 1rem;
  border-bottom: solid #f4f7f8 4px;
  position: relative;
  z-index: 1; }

.k__monitoring__preview__card .k__social__preview__medias img {
  width: 49.5%;
  margin-bottom: 0.5%;
  margin-right: 0.5%;
  vertical-align: middle; }

.k__monitoring__preview__card .k__social__preview__medias.--odd img:first-child {
  width: 100%; }

.k__monitoring__preview__header {
  position: relative;
  display: flex;
  margin-bottom: 0.5rem; }
  .k__monitoring__preview__header .k__network__error {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }

.k__monitoring__preview__message {
  margin-bottom: 1rem;
  font-weight: 400;
  line-height: 1.35;
  overflow: hidden;
  word-break: break-word; }

.k__monitoring__preview__message__mention {
  color: #22e39e; }

.k__monitoring__preview__comments__wrapper {
  margin-bottom: 1rem; }
  .k__monitoring__preview__comments__wrapper > .k__monitoring__preview__card__comments {
    margin: 0; }

.k__monitoring__preview__card__comments {
  margin-left: 1rem; }
  .k__monitoring__preview__card__comments.--lvl-1 {
    margin: 0; }

.k__monitoring__preview__card__comment__avatar {
  line-height: 0;
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem; }

.k__monitoring__preview__card__comment__avatar__thumb {
  width: 100%;
  height: 100%;
  border-radius: 50%; }

.k__monitoring__preview__card__comment {
  display: flex;
  align-items: flex-start;
  margin: 0.5rem 0; }
  .k__monitoring__preview__card__comment.--is-owner .k__monitoring__preview__card__comment__message__inner {
    background: #3479ea;
    color: #fff; }

.k__monitoring__preview__card__comment__message__inner {
  position: relative;
  background: #f0f2f5;
  padding: 8px 16px 9px 16px;
  border-radius: 18px;
  font-size: 0.9rem;
  font-weight: 500; }

.k__monitoring__preview__card__comment__likes {
  font-size: 0.9rem;
  position: absolute;
  display: flex;
  right: 0;
  bottom: 0;
  background: #fff;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;
  border-radius: 10px;
  padding: 2px 4px;
  transform: translateX(62.5%); }
  .k__monitoring__preview__card__comment__likes .k__icon {
    width: 1rem;
    height: 1rem;
    margin-right: 2px; }

.k__monitoring__preview__card__comment__actions {
  user-select: none;
  font-size: 0.75rem;
  display: flex;
  padding: 0.25rem 0; }
  .k__monitoring__preview__card__comment__actions > span {
    opacity: 0.5; }

.k__monitoring__preview__card__comment__action {
  font-weight: bold;
  color: inherit;
  text-decoration: none; }
  .k__monitoring__preview__card__comment__action.--active {
    color: #22e39e; }
  .k__monitoring__preview__card__comment__action::after {
    display: inline-block;
    margin: 0 0.15rem;
    content: "-"; }
  .k__monitoring__preview__card__comment__action:hover {
    text-decoration: underline; }

.k__social__preview__actions {
  position: relative;
  text-align: center;
  border-top: solid #eee 1px;
  padding: 0.75rem 0 1rem 0;
  min-height: 3.25rem; }

.k__social__preview__action {
  color: #1a73e8; }

.k__section {
  border-top: solid #ebf0f2 1px;
  border-bottom: solid #ebf0f2 1px;
  margin: 1rem 0; }

.k__section__header {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.75rem 0; }

.k__section__header__status {
  font-size: 1.25rem;
  font-weight: 300;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0.5; }

.k__section__content {
  padding: 0 0 1rem 0;
  display: none; }
  .k__section__content.--visible {
    display: block; }

.k__bubble {
  font-size: 0.9em;
  display: inline-flex;
  height: 2em;
  min-width: 2em;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  background: #4a4a4a;
  color: #fff;
  margin-left: 0.5rem; }

fieldset[disabled] .multiselect {
  pointer-events: none; }

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block; }

.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent; }

.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite; }

.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite; }

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1; }

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0; }

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

.multiselect {
  position: relative;
  text-align: left;
  border-radius: 2rem; }

.multiselect:focus {
  outline: none; }

.multiselect--disabled {
  pointer-events: none;
  opacity: 0.6; }

.multiselect--active {
  z-index: 50; }

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.multiselect--active .multiselect__select {
  transform: rotate(180deg); }

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top; }

.multiselect__input:-ms-input-placeholder {
  color: #35495e; }

.multiselect__input::placeholder {
  color: #35495e; }

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto; }

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf; }

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none; }

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px; }

.multiselect__tags-wrap {
  display: inline-block;
  padding-bottom: 0.25rem; }

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px; }
  .multiselect__tags .k__label {
    margin: 0.15rem; }

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis; }

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px; }

.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px; }

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e; }

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff; }

.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8; }

.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer; }

.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease; }

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ""; }

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px; }

.multiselect--active .multiselect__placeholder {
  display: none; }

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 50;
  -webkit-overflow-scrolling: touch; }

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top; }

.multiselect--above .multiselect__content-wrapper {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom: none; }

.multiselect__content::webkit-scrollbar {
  display: none; }

.multiselect__element {
  display: block;
  margin: 0.5rem 0 0 0; }

.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap; }

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px; }

.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff; }

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff; }

.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700; }

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver; }

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff; }

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff; }

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6; }

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none; }

.multiselect__option--group {
  background: #ededed;
  color: #35495e; }

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff; }

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e; }

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede; }

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff; }

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff; }

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease; }

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0; }

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top; }

[dir="rtl"] .multiselect {
  text-align: right; }

[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px; }

[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 40px; }

[dir="rtl"] .multiselect__content {
  text-align: right; }

[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0; }

[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px; }

[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px; }

@keyframes spinning {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(2turn); } }

.k__table__tools {
  position: relative;
  min-height: 5.75rem;
  padding: 0 1rem 0 1rem;
  z-index: 9999; }
  .k__table__tools.--has-settings .k__selects {
    margin-right: 4rem; }
  @media (min-width: 800px) {
    .k__table__tools {
      padding: 0.25rem 0; }
      .k__table__tools .k__select {
        max-width: 27.5%; } }

.k__selects {
  margin: 1rem 0; }
  .k__selects .k__bton {
    margin: 0;
    width: 100%; }
  .k__selects .k__select {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  @media (min-width: 800px) {
    .k__selects {
      margin: 1rem -0.75rem;
      display: flex;
      align-items: center; }
      .k__selects .k__bton {
        margin: 0;
        width: auto; }
      .k__selects .k__select {
        width: auto;
        margin: 0.75rem; }
      .k__selects.--mini .k__select {
        flex: 0 0 16rem; } }

.k__select {
  font-size: 16px;
  border: solid #ebeced 1px;
  border-radius: 2rem;
  flex: 1;
  margin: 0.75rem;
  transition: opacity 0.08333s cubic-bezier(0, 0.85, 0.18, 0.99), transform 0.08333s cubic-bezier(0, 0.85, 0.18, 0.99);
  min-width: 6rem; }
  .k__select.--hidden {
    opacity: 0;
    transform: scale(0.95);
    display: none; }
  .k__select.--nativ {
    position: relative;
    height: 3rem;
    width: 100%;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    margin: 1rem 0;
    min-width: 15ch;
    max-width: 100%;
    border: 1px solid #ebeced;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    border-radius: 2rem; }
    .k__select.--nativ select, .k__select.--nativ::after {
      grid-area: select; }
    @media (min-width: 800px) {
      .k__select.--nativ {
        max-width: 24ch; } }
    .k__select.--nativ:not(.select--multiple)::after {
      content: "";
      justify-self: end;
      width: 0.8em;
      height: 0.5em;
      background-color: var(--select-arrow);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%); }
    .k__select.--nativ > select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 1em 0 0;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      z-index: 1;
      color: #495b61;
      outline: none; }
      .k__select.--nativ > select::-ms-expand {
        display: none; }
    .k__select.--nativ::after {
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      color: #999;
      content: "";
      position: relative;
      right: 0;
      top: 0.1rem; }
  .k__select.--custom .multiselect__tags-wrap {
    display: none; }
  .k__select .multiselect__tags {
    border: none;
    border-radius: 0;
    background: none; }
  .k__select .multiselect__single,
  .k__select .multiselect__input {
    font-size: 16px;
    margin-top: 0.2rem;
    opacity: 0.75;
    border: none;
    box-shadow: none !important; }
  .k__select .multiselect__single > strong {
    display: inline-block;
    line-height: 1;
    padding: 0.35rem 0.5rem;
    margin-right: 0.25rem;
    border-radius: 1rem;
    background: #fb4747;
    background: #22e39e;
    color: white;
    font-size: 0.8rem; }
  .k__select .multiselect__content-wrapper {
    animation: slideFromBottom 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
    transform-origin: top center;
    top: 100%;
    right: 0;
    left: -1px;
    overflow-x: hidden;
    border: solid #eaeaea 1px;
    box-shadow: 0px 1px 0.5rem rgba(0, 0, 0, 0.04), 0px 1px 1rem rgba(0, 0, 0, 0.06); }
  .k__select .multiselect__content {
    padding: 1rem 0;
    max-width: calc(100% + 1px); }
  .k__select .multiselect__option {
    position: relative;
    min-height: 0;
    line-height: 1.2;
    padding: 0 1rem 0 2.75rem;
    font-weight: normal;
    background: none;
    color: inherit; }
    .k__select .multiselect__option::before {
      content: "";
      display: block;
      position: absolute;
      height: 1rem;
      width: 1rem;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2px;
      border: solid #ddd 1px; }
    .k__select .multiselect__option::after {
      padding: 0;
      opacity: 0.75;
      content: "";
      display: block;
      position: absolute;
      height: 0.7rem;
      width: 0.7rem;
      left: 1.3rem;
      top: 50%;
      transform-origin: center center;
      transition: transform 0.1s;
      transform: translateY(-50%) scale(0);
      background: url(../icon/check.svg) no-repeat; }
  .k__select .multiselect__option--selected {
    font-weight: 600;
    background: none;
    color: inherit; }
    .k__select .multiselect__option--selected.multiselect__option--highlight {
      font-weight: 600;
      background: none;
      color: inherit; }
      .k__select .multiselect__option--selected.multiselect__option--highlight::after {
        transform: translateY(-50%) scale(1);
        background: url(../icon/check.svg) no-repeat; }
    .k__select .multiselect__option--selected::after {
      transform: translateY(-50%) scale(1);
      background: url(../icon/check.svg) no-repeat; }
  .k__select .multiselect__option--highlight {
    font-weight: normal;
    background: none;
    color: inherit; }

.multiselect__option__content {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .multiselect__option__content:hover .multiselect__option__only {
    display: inline-block; }

.multiselect__option__title {
  font-weight: 500;
  padding: 0;
  font-size: 1rem; }
  .multiselect__option__title::first-letter {
    text-transform: uppercase; }

.multiselect__option__only {
  position: absolute;
  right: 1rem;
  background: #22e39e;
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  display: none;
  margin-left: 1rem;
  color: #fff;
  border-radius: 1.5rem;
  animation: appear 0.1s cubic-bezier(0.25, 0.1, 0.25, 1); }

.multiselect__tag {
  display: none !important; }

.k__selector__check__all {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #22e39e; }
  .k__selector__check__all::before {
    content: "";
    display: inline-block;
    height: 1rem;
    width: 1rem;
    left: 1rem;
    top: 50%;
    border-radius: 2px;
    box-shadow: inset 0 0 0 0.5rem #22e39e;
    margin: 0 0.5rem 0 1rem;
    opacity: 0.75; }
  .k__selector__check__all .k__selector__check__all__check {
    padding: 0;
    line-height: 0;
    content: "";
    display: block;
    position: absolute;
    height: 0.625rem;
    width: 0.625rem;
    left: 1.2rem;
    transform-origin: center center;
    transition: transform 0.1s;
    transform: translateY(0) scale(0);
    fill: #fff; }
  .k__selector__check__all.--all-selected .k__selector__check__all__check {
    transform: translateY(0) scale(1); }

.k__shortcut {
  position: relative;
  text-decoration: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 10em;
  border-radius: 0.5rem;
  user-select: none; }
  .k__shortcut .k__icon {
    width: 2rem; }
  .k__shortcut.--mini {
    font-size: 0.8rem; }
  .k__shortcut.--extern::after {
    content: "";
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    height: 1rem;
    width: 1rem;
    background-size: contain;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAoCAMAAABDwLOoAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAH5QTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgraD7AAAACp0Uk5TAEFZWCcLTjdMIiooDFYQUhdIJFEOBkozVBEJTTQYJSFAFAgxRTAFHVAB+r85zgAAALFJREFUeJyt00cOgzAQQNEh9N6M6b3f/4KRhRCJSEQm5i/tt7DGGoDbE4THKVGUJKyRZUU5K1XFGqY07Q4DoOt3GMO4fvO1Mc2zwBvLsu07zKcfxRvHeb9xXc/zfbwJglcRhoQARBGlWBPHh0iSNN1OsyzPcaYoDmOax0RkuSwxpqp2kefwpV8MIbupax4D0DSbaVs+03XbJvc9n2HzGgamxnGaeAxrngVhWShdVz7zd0+4+xOdNJgpUwAAAABJRU5ErkJggg=="); }
  .k__shortcut:not(.--disabled) {
    color: inherit;
    box-shadow: inset 0 0 0 0 #22e39e, 0 4px 20px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    transform: scale(1);
    transition: box-shadow 0.25s cubic-bezier(0, 0.85, 0.18, 0.99), color 0.25s cubic-bezier(0, 0.85, 0.18, 0.99), transform 0.25s cubic-bezier(0, 0.85, 0.18, 0.99), color 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
    .k__shortcut:not(.--disabled) .k__icon {
      color: #22e39e;
      margin-bottom: 0.25rem; }
    .k__shortcut:not(.--disabled):hover, .k__shortcut:not(.--disabled):focus {
      color: #22e39e;
      outline: none;
      box-shadow: inset 0 0 0 2px #22e39e, 0 4px 20px rgba(0, 0, 0, 0.05);
      transform: scale(0.975); }
    .k__shortcut:not(.--disabled).--mini {
      background: none;
      transition: none;
      transform: none;
      box-shadow: none;
      overflow: hidden;
      z-index: 1; }
      .k__shortcut:not(.--disabled).--mini:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #22e39e;
        z-index: -1;
        transform-origin: center center;
        transform: scale(0.75);
        opacity: 0;
        transition: transform 0.25s cubic-bezier(0, 0.85, 0.18, 0.99), opacity 0.25s cubic-bezier(0, 0.85, 0.18, 0.99); }
      .k__shortcut:not(.--disabled).--mini:hover, .k__shortcut:not(.--disabled).--mini:focus {
        color: inherit; }
        .k__shortcut:not(.--disabled).--mini:hover::before, .k__shortcut:not(.--disabled).--mini:focus::before {
          opacity: 0.1;
          transform: scale(1); }
  .k__shortcut.--disabled {
    color: #d5d5d5;
    pointer-events: none;
    box-shadow: inset 0 0 0 1px #d5d5d5; }
    .k__shortcut.--disabled.--mini {
      box-shadow: none; }
    .k__shortcut.--disabled::after {
      content: "";
      position: absolute;
      height: 0.9em;
      width: 0.9em;
      background: url("../icon/lock.svg") no-repeat;
      top: 2.25em;
      right: 3.25em;
      opacity: 0.25; }

.k__shortcut__label {
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.25; }

.k__shortcut__desc {
  display: inline-block;
  margin-top: 0.25rem;
  text-align: center;
  font-size: 0.85714em;
  font-weight: 500;
  opacity: 0.5;
  line-height: 1;
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.k__modal__social {
  position: fixed;
  text-align: left;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0; }
  .k__modal__social .k__modal__header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 4rem;
    padding: 1rem;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.04), 0px 1px 4rem rgba(0, 0, 0, 0.06);
    z-index: 3; }
    .k__modal__social .k__modal__header h3 {
      margin: 0;
      font-size: 1rem; }
  .k__modal__social .k__modal__inner {
    position: relative;
    top: 4rem;
    height: calc(100% - 8rem); }
  .k__modal__social .k__social__edit {
    display: flex;
    height: 100%; }
  .k__modal__social .k__social__edit__left,
  .k__modal__social .k__social__edit__center,
  .k__modal__social .k__social__edit__right {
    animation: slideFromBottomSoft 0.2s; }
  .k__modal__social .k__social__edit__left {
    overflow: auto;
    flex: 0 0 28rem;
    background: #f5f8f9;
    border-right: solid #f4f7f8 2px;
    max-width: 100%; }
  .k__modal__social .k__social__edit__center {
    overflow: auto;
    padding: 1rem;
    flex: 1;
    overflow-x: hidden; }
  .k__modal__social .k__social__edit__right {
    overflow: auto;
    padding: 0 0 2rem 0;
    flex: 0 0 28rem;
    background: #f1f2f5; }
  @media (min-width: 800px) {
    .k__modal__social .k__social__edit__center {
      padding: 2rem; } }

.k__modal__footer {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: 4rem;
  box-shadow: 0px 1px 1rem rgba(0, 0, 0, 0.04), 0px 1px 4rem rgba(0, 0, 0, 0.06);
  background: #fff;
  z-index: 1; }

.k__modal__footer__actions {
  padding: 0 0 0 0.75rem;
  display: flex;
  align-items: center; }
  @media (min-width: 800px) {
    .k__modal__footer__actions {
      padding: 0 1rem 1rem 1rem; } }

.k__modal__footer__actions__right {
  flex: 1;
  text-align: right;
  animation: slideFromBottomSoft 0.2s; }

.k__modal__footer__action {
  margin: 0.5rem 0.25rem; }
  @media (min-width: 800px) {
    .k__modal__footer__action {
      margin: 0.5rem; } }

.k__table__wrapper {
  max-width: 100%;
  overflow-x: auto; }

.k__table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  font-size: 0.8em;
  color: #3f444a;
  margin: 2rem 0; }
  .k__table th,
  .k__table td {
    text-align: left;
    padding: 0.85rem 0.5rem; }
    .k__table th.--disabled,
    .k__table td.--disabled {
      display: none !important; }
  .k__table th {
    user-select: none;
    font-weight: normal;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    border-bottom: solid rgba(0, 0, 0, 0.05) 1px; }
    .k__table th span {
      color: #888; }
  .k__table.--no-data {
    position: relative;
    pointer-events: none;
    opacity: 0.5; }
    .k__table.--no-data .k__table__header {
      opacity: 0.25; }
    .k__table.--no-data .k__table__body {
      display: block;
      height: 4em; }
    .k__table.--no-data .k__table__foot {
      display: none; }
    .k__table.--no-data::after {
      content: "no data";
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      padding: 0.5em 1em;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 4px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .k__table .k__table__select {
    padding: 0;
    width: 3rem; }

.k__table__header .--sorter {
  cursor: pointer;
  display: flex;
  align-items: center; }

.k__table__header .--sorting {
  font-weight: 800; }
  .k__table__header .--sorting span {
    color: #444; }
  .k__table__header .--sorting svg {
    opacity: 1; }

.k__table__header svg {
  width: 0.5rem;
  transform: translateX(0.5em) rotateZ(-90deg);
  opacity: 0.15;
  transition: transform 0.2s, opacity 0.2s; }
  .k__table__header svg.ASC {
    transform: translateY(0) translateX(0.5em) rotateZ(90deg); }

.k__table__line:nth-child(even) {
  background: rgba(0, 0, 0, 0.015); }

.k__table__col {
  line-height: 1.3; }
  .k__table__col .k__table__col__arrow {
    width: 0.6em;
    transform: translateX(0.5em) rotateZ(-90deg); }
  .k__table__col .--label-missed {
    color: #c94031; }
  .k__table__col .--label-received {
    color: #3d7e41; }
  .k__table__col.--is-up .k__icon {
    transform: translateX(0.5em) rotateZ(-90deg); }
  .k__table__col.--is-down .k__icon {
    transform: translateX(0.5em) rotateZ(90deg); }
  .k__table__col.--is-good .k__icon {
    fill: green; }
  .k__table__col.--is-bad .k__icon {
    fill: red; }
  .k__table__col .--result {
    font-size: 0.9rem;
    max-width: 100%;
    overflow-wrap: break-word;
    font-weight: bold; }
  .k__table__col .--result-title {
    display: block;
    color: #152dc8;
    font-weight: 800; }
  .k__table__col .--result-link {
    display: block;
    color: #499134;
    margin-bottom: 0.25em;
    font-weight: normal; }
  .k__table__col .--no-val {
    font-weight: 200;
    opacity: 0.75; }
  .k__table__col .--unit {
    opacity: 0.75;
    margin-left: 0.25em;
    font-size: 0.75em; }
  .k__table__col .k__table__col__img {
    background: #f4f4f4;
    line-height: 1;
    position: relative;
    width: 16rem;
    height: 16rem;
    overflow: hidden;
    border-radius: 0.5rem;
    margin-top: 0.75rem;
    animation: slideFromBottom 0.2s; }
    .k__table__col .k__table__col__img:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .k__table__col .k__table__col__img img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%); }

.k__table__col__breadcrumb__label {
  background: #666;
  font-size: 0.7rem;
  display: inline-block;
  padding: 0.1rem 0.35rem;
  border-radius: 4px;
  color: white;
  margin-bottom: 0.25rem; }

.k__table__col__breadcrumbs {
  display: block;
  font-size: 0.75rem;
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.15rem 0 0.35rem 0; }

.k__table__col__breadcrumb::before {
  content: "/";
  margin: 0 0.25rem;
  opacity: 0.5; }

.k__table__col__breadcrumb:first-child::before {
  content: none; }

.k__table__col__titles {
  display: block;
  font-size: 0.9rem; }

.k__table__col__title.--name, .k__table__col__title.--adGroup, .k__table__col__title.--keyword {
  font-weight: bold; }

.k__table__col__title.--title {
  color: #152dc8;
  font-weight: bold; }

.k__table__col__title.--link {
  display: block;
  color: #499134;
  margin: 0.1rem 0 0.2rem 0;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.k__table__col__title.--description {
  color: #404041;
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
  display: block; }

.k__table__col__title:empty {
  display: none; }

.k__table__col__extension {
  display: block; }
  .k__table__col__extension.--CALL {
    color: #448bf7;
    margin: 0.25em 0 0.15em; }
  .k__table__col__extension.--CALLOUT {
    opacity: 0.5; }
    .k__table__col__extension.--CALLOUT > span {
      display: inline-block;
      margin-right: 0.25em; }
  .k__table__col__extension.--SITELINK {
    color: #4965d6;
    display: flex;
    flex-flow: row wrap;
    margin: 0.25em -0.5em; }
    .k__table__col__extension.--SITELINK > span {
      display: block;
      padding: 0.25em 0.5em;
      flex: 0 0 50%;
      font-weight: bold; }

.k__table__col__content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .k__table__col__content .k__label {
    margin-top: 0.25rem; }
    .k__table__col__content .k__label.--danger .k__icon {
      transform: translateX(0.5em) rotateZ(90deg); }
  @media (min-width: 1025px) {
    .k__table__col__content {
      display: block; } }

@media (min-width: 1025px) {
  .k__table__col__content__metric {
    display: none; } }

.k__table__col__content__value {
  text-align: right;
  margin-right: 1rem;
  font-weight: bold; }
  @media (min-width: 1025px) {
    .k__table__col__content__value {
      font-weight: normal;
      margin: 0;
      text-align: left; } }

.k__table__col__pourcent {
  height: 0.75em;
  flex: 1;
  margin-left: 0.5em; }

.k__table__col__pourcent__width {
  height: 0.75em;
  display: block;
  background: var(--color-highlight);
  transform-origin: left center;
  animation: scaleXIn 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); }

.k__table__foot {
  font-weight: bold;
  line-height: 2; }
  .k__table__foot .k__table__line td {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    border-bottom: solid #eee 1px;
    font-size: 1rem;
    padding: 1rem 0; }
  .k__table__foot .k__table__line .--result {
    color: #22e39e;
    font-size: inherit; }
  .k__table__foot .k__table__col__metric {
    display: block; }
  @media (min-width: 1025px) {
    .k__table__foot .k__table__line td {
      font-size: inherit; }
    .k__table__foot .k__table__line .--result {
      color: inherit;
      font-size: 0.9rem; }
    .k__table__foot td {
      width: auto;
      text-align: left; }
    .k__table__foot .k__table__col__metric {
      display: none; } }

.k__table__header {
  display: none; }

.k__table,
.k__table__body,
.k__table__col,
.k__table__foot {
  display: block; }

.k__table__body {
  display: flex;
  flex-flow: row wrap; }

.k__table__line {
  display: block;
  padding: 1rem;
  flex: 0 0 100%;
  max-width: 100%; }
  .k__table__line .k__table__col {
    padding-bottom: 0; }

@media (min-width: 640px) {
  .k__table__line {
    flex: 0 0 50%; } }

@media (min-width: 800px) {
  .k__table__line {
    flex: 0 0 33.33333%; } }

@media (min-width: 1025px) {
  .k__table {
    display: table; }
  .k__table__header {
    display: table-header-group; }
  .k__table__body {
    display: table-row-group; }
  .k__table__foot {
    display: table-footer-group; }
  .k__table__line {
    padding: 0;
    display: table-row !important; }
    .k__table__line .k__table__col {
      display: table-cell !important;
      padding-bottom: 0.85rem; } }

.k__tabs__nav__items {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  overflow-x: auto;
  margin: 0 -0.5rem; }
  @media (min-width: 800px) {
    .k__tabs__nav__items {
      margin: 0 -1rem; } }

.k__tabs__nav__item {
  display: inline-flex;
  align-items: center;
  color: currentColor;
  user-select: none;
  position: relative;
  background: none;
  border: none;
  opacity: 0.75;
  padding: 1rem 0;
  margin: 0 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: none;
  white-space: nowrap;
  line-height: 1.2; }
  .k__tabs__nav__item::before {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    right: 0;
    bottom: 0;
    left: 0;
    background: #22e39e;
    transform-origin: center bottom;
    transform: scaleY(0);
    transition: transform 0.125s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__tabs__nav__item:hover, .k__tabs__nav__item:focus, .k__tabs__nav__item:active, .k__tabs__nav__item.--active {
    opacity: 1;
    outline: none;
    box-shadow: none; }
    .k__tabs__nav__item:hover::before, .k__tabs__nav__item:focus::before, .k__tabs__nav__item:active::before, .k__tabs__nav__item.--active::before {
      transform: scaleY(1); }
  .k__tabs__nav__item.--disabled {
    pointer-events: none;
    opacity: 0.25; }
  .k__tabs__nav__item .k__icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.25rem; }
  @media (min-width: 800px) {
    .k__tabs__nav__item {
      font-size: 1rem;
      margin: 0 1rem; }
      .k__tabs__nav__item::before {
        background: currentColor; } }

.k__content__tab {
  display: none;
  animation: slideFromBottomSoft 0.2s; }
  .k__content__tab.--active {
    display: block; }

.k__tabs__content {
  position: relative; }
  .k__tabs__content .k__dates {
    margin-left: -1.618rem;
    margin-right: -1.618rem;
    border-bottom: solid #eee 1px; }
    .k__tabs__content .k__dates .k__dates__pickers {
      margin: 0; }
    .k__tabs__content .k__dates .k__bton {
      margin-left: 1rem; }
    .k__tabs__content .k__dates .datepicker {
      top: 1rem !important; }
    .k__tabs__content .k__dates .k__banner__actions {
      display: flex;
      align-items: center;
      padding: 0.809rem 1.618rem; }
  .k__tabs__content .k__date__fragment {
    color: currentColor;
    font-size: 1rem; }
    .k__tabs__content .k__date__fragment.--strong {
      font-size: 1.15rem; }
  @media (min-width: 800px) {
    .k__tabs__content .k__dates {
      position: absolute;
      right: 1rem;
      top: 0;
      border: solid #eee 1px;
      border-radius: 2rem;
      background: #fff;
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      transform: translateY(-50%);
      z-index: 9;
      margin: 0; }
      .k__tabs__content .k__dates .k__banner__actions {
        padding: 0; }
      .k__tabs__content .k__dates .datepicker {
        right: -5.5rem; } }

.k__app__list__edit__content .k__timeline::before {
  top: 2rem;
  background: #d6dcde;
  left: 2rem;
  bottom: 2rem; }

.k__app__list__edit__content .k__timeline .k__timeline__event {
  justify-content: flex-start; }

.k__app__list__edit__content .k__timeline .k__timeline__event__number {
  height: 1rem;
  width: 1rem;
  text-indent: 9999px;
  border: 2px solid #d6dcde;
  margin-left: 1.5rem;
  background: #fff; }

.k__app__list__edit__content .k__timeline .k__timeline__event__date  {
  font-size: 0.85rem;
  font-weight: bold;
  margin: 0 1.5rem 0 0.75rem; }

.k__app__list__edit__content .k__timeline .k__timeline__event__body {
  background: #f7f8f9;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  max-width: 100%;
  flex: 1; }
  .k__app__list__edit__content .k__timeline .k__timeline__event__body::after {
    border-right-color: #f7f8f9;
    top: 50%;
    transform: translateY(-50%); }

.k__app__list__edit__content .k__timeline .k__timeline__event__body__title {
  font-size: 0.95rem;
  font-weight: 400;
  margin: 0;
  line-height: 1.35; }

.k__tool__wrapper {
  position: relative;
  display: inline-block;
  position: absolute;
  top: 2rem;
  right: 0.5rem;
  z-index: 9999; }
  @media (min-width: 800px) {
    .k__tool__wrapper {
      top: 2.6rem;
      right: 1rem; } }

.k__tool__content {
  font-size: 0.9rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .k__tool__content.--right {
    transform: translate(0, -50%);
    left: auto;
    right: 0; }
  .k__tool__content.--left {
    transform: translate(0, -50%);
    left: 0; }

.k__kpi__tooltip {
  position: absolute;
  font-size: 0.8em;
  border-radius: 4px;
  padding: 0.5em 0.75em;
  color: #fff;
  background: #4f5051;
  top: 0;
  left: 50%;
  width: 14em;
  transform: translate(-50%, -100%);
  animation: fadeIn 0.1s cubic-bezier(0, 0.85, 0.18, 0.99); }
  .k__kpi__tooltip::before {
    content: "";
    display: block;
    position: absolute;
    left: 1em;
    bottom: -6px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #4f5051; }

.--mobile .k__kpi__tooltip {
  display: none; }

.k__title__group {
  display: flex;
  align-items: center;
  margin: -.5rem; }
  .k__title__group > * {
    margin: .5rem; }

@media (min-width: 800px) {
  .--only-mobile {
    display: none; } }

.--not-mobile {
  display: none; }
  @media (min-width: 800px) {
    .--not-mobile {
      display: inherit; } }

.--only-desktop {
  display: none; }
  @media (min-width: 1200px) {
    .--only-desktop {
      display: inherit; } }

@media (min-width: 1200px) {
  .--not-desktop {
    display: none; } }

.k__wrapper {
  position: relative;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  margin: 1rem 0;
  min-height: 6.75rem; }
  .k__wrapper.--white {
    background-color: #fff; }
  .k__wrapper.--shadow {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04), 0px 1px 15px rgba(0, 0, 0, 0.06); }
  .k__wrapper.--table {
    padding-top: 0.25rem;
    min-height: 16rem; }
  .k__wrapper .--freeze {
    user-select: none;
    pointer-events: none; }
  @media (min-width: 800px) {
    .k__wrapper {
      margin: 2rem 0 4rem 0;
      padding: 1rem; } }

.k__wrapper__error {
  position: absolute;
  left: 50%;
  top: 62.5%;
  transform: translate(-50%, -50%); }
  .k__wrapper__error .k__label {
    animation: slideFromBottomSoft 0.2s; }

:root {
  --scroll1: '1px';
  --scroll2: '1px';
  --scroll3: '1px';
  font-size: 13px;
  font-size: clamp(13px, 1vw, 17px);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }

*,
*::before,
*::after {
  box-sizing: border-box; }

.k__h1,
.k__h2,
.k__h3,
.k__h4,
.k__h5,
.k__h6 {
  color: #3f444a;
  margin: 0; }

.k__html {
  height: 100%; }

.k__body {
  background-color: #f8fafc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.618;
  min-height: 100%;
  color: #495b61; }

.svg-sprites {
  display: none; }

.k__login__header .k__img {
  width: 8rem; }

.k__tabs__header {
  background: linear-gradient(106.92deg, #47cff4 0%, #0b8db0 100%); }

/*# sourceMappingURL=maps/style.min.css.map */
