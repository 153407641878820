
















































































































































.bdc__password-change {
    &__form {

        &__error {
            color: red;
        }

        &__password {
            &__strength {
                margin: .5rem 0 0 0;
                height: .25rem;
                border-radius: .5rem;
                background: #eef3f6;
                &.--note-0 {
                    .bdc__password-change__form__password__strength__note {
                        background-color: #F16344;
                        transform: scaleX(0.05);
                    }
                    .bdc__password-change__form__password__strength__explanation {
                        color: #F16344;
                    }
                }
                &.--note-1 {
                    .bdc__password-change__form__password__strength__note {
                        background-color: #9D8F98;
                        transform: scaleX(0.25);
                    }
                    .bdc__password-change__form__password__strength__explanation {
                        color: #9D8F98;
                    }
                }
                &.--note-2 {
                    .bdc__password-change__form__password__strength__note {
                        background-color: #44C5E4;
                        transform: scaleX(0.5);
                    }
                    .bdc__password-change__form__password__strength__explanation {
                        color: #44C5E4;
                    }
                }
                &.--note-3 {
                    .bdc__password-change__form__password__strength__note {
                        background-color: #44D7C3;
                        transform: scaleX(0.75);
                    }
                    .bdc__password-change__form__password__strength__explanation {
                        color: #44D7C3;
                    }
                }
                &.--note-4 {
                    .bdc__password-change__form__password__strength__note {
                        background-color: #44F195;
                        transform: scaleX(1);
                    }
                    .bdc__password-change__form__password__strength__explanation {
                        color: #44F195;
                    }
                }

                &__note {
                    margin: .75rem 0 .25rem 0;
                    height: 100%;
                    border-radius: .5rem;
                    transform-origin: left center;
                    transition: transform 0.5s ease, background 0.5s ease;
                }

                &__explanation {
                    margin: 0 0 1rem 0;
                    font-size: .75rem;
                    font-weight: 500;
                    text-align: right;
                }
            }
        }
    }
}
