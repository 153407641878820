@import "../klixi/regicom/dist/css/style.min.css";
@import "../klixi/regicom/src/scss/vars";

.bdc__recovery {
  &.k__modal__wrapper {
    z-index: 10;
  }

  .k__modal__overlay {
    background: linear-gradient(
      106.92deg,
      lighten($highlight2, 55%) 0%,
      lighten($highlight2, 35%) 100%
    );
  }

  .k__modal__content {
    font-size: 1.25rem;
  }
}

.bdc__iframe {
  border: none;
  width: 100%;
  min-height: 800px;
  height: 100%;
}

.bdc__network-members {
  &__member {
    vertical-align: top;
    &__service {
      margin: 0.1rem;
      padding: 0.1rem 0.5rem;
      border: 1px solid $highlight1;
      border-radius: 1rem;
      color: $highlight1;
      text-align: center;
      display: inline-block;
    }
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: blink 1000ms infinite;
}

/* access */

.bdc-access {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 300px;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__pages {
      padding: 1rem 0;
    }
  }

  &__table tr td,
  &__table tr th {
    border: 1px solid;
    padding: 5px;
  }
}

/* quick fixes */

a.bdc__link,
a.bdc__link:visited,
a.bdc__link:active {
  color: $highlight2;
}

.k__banner__info {
  font-size: 1.2rem;
}

.k__featured {
  align-items: flex-start;

  .k__featured__desc {
    margin-top: 3rem;
    @media (min-width: 800px) {
      padding-right: 1rem;
      padding-left: 5rem;
    }
  }
}

.k__modal__header .k__img {
  width: 10rem;
}

.k__shortcut__desc {
  line-height: 1rem;
}

.k__logo {
  max-width: 10rem;
}

.k__footer {
  background: linear-gradient(0.297turn, $highlight2, #004357);
  opacity: 1;
  max-width: none;

  .k__logo {
    opacity: 1;
    width: 8rem;
    display: inline-block;
  }

  .k__footer__items {
    max-width: 100rem;
    margin: 0 auto;

    @media (min-width: 800px) {
      align-items: center;
    }

    color: #fff;

    .k__footer__item {
      //for mobile
      margin: 1rem 0;

      flex: 1;

      @media (min-width: 800px) {
        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .k__footer__social-brand,
  .k__footer__social-brand:visited,
  .k__footer__social-brand:active {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #fff;
  }
}

.k__bton.--full {
  justify-content: center;
}

.k__network {
  background: white;
  border-radius: 0.5rem;
}

.k__network .k__shortcut {
  box-shadow: inset 0 0 0 0 #ec6d8b, 0 4px 20px rgb(0 0 0 / 15%);
}

.k__network .k__icon,
.k__network .k__h3 {
  color: #ec6d8b;
}

.k__network .k__shortcut.--disabled .k__icon {
  color: #d5d5d5;
}
