$time: 0.25s;

$trans: cubic-bezier(0, 0.85, 0.18, 0.99);

$gutter: 1.618rem;

$base: #495b61;
$title: #3f444a;
$grey: #eef3f6;

// $highlight1: #5eca98;
$highlight2: #4e81ab;
$highlight1: #22e39e;

$radius: 0.5rem;
$borderwidth: 1px;
