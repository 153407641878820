


























.k__modal__close {
  position: absolute;
  top: 0;
  right: 1rem;
  font-size: 2rem;
  color: #495b61;
  cursor: pointer;
}
