
































































































.bdc-message {
  .k__modal__close {
    border-radius: 3rem;
    border: 1px solid;
    background: white;
    padding: 0.5rem;
    position: absolute;
    width: 3rem;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    z-index: 1;
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }

  .k__modal__content {
    margin: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
    //should be done through image, video or html
    background: transparent;
    box-shadow: none;
  }

  &--clickable {
    .k__modal__content {
      margin-top: -60px;
      cursor: pointer;
    }
  }

  &--media {
    .k__modal__content {
      padding: 0;
      background: transparent;
      box-shadow: none;
      width: 80%;
    }
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    width: 100%;
  }

  &__video {
    text-align: center;
    margin: auto;
    width: 100%;
  }

  &__actions {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
