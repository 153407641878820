























































.bdc-file-input {
  &--error {
    color: #9a1c1c;
  }
}
