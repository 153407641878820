






































































































































































































































































.bdc-message-edit {
  &__mediapreview {
    margin: 1rem;
    text-align: center;
  }

  &__colorpreview {
    width: 24px;
    height: 24px;
    margin-left: 1rem;
    border: 1px solid;
  }
}

.k__bton--blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
